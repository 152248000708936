import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import SignaturePad from "react-signature-canvas";
import { BsChevronDoubleRight } from 'react-icons/bs';

import { Routes, Route, useParams, useLocation } from 'react-router-dom';

const SignatureForm = () => {
  const useQuery= () => {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const applicationCode = query.get('code');
  // console.log("Application", applicationCode);
  const [signatureUrl, setSignatureUrl] = useState(null); // create a state that will contain our image url
  const [applicant, setApplicant] = useState([]);
  const [signatureShow, setSignatureShow] = useState(false);

  

  const saveSignature = (e) => {
    setSignatureUrl(signature.current.getTrimmedCanvas().toDataURL("image/png"));
    // setFormData({
    //   ...formData,
    //   signature: signature.current.getTrimmedCanvas().toDataURL("image/png"),
    // });

    // console.log("Current", signature.current.getTrimmedCanvas().toDataURL("image/png"));
    // console.log("Set Signature", signatureUrl);

    formSubmit(e);
  }

  const signature = useRef({});

  const clearSignature = (e) => {
    setSignatureShow(true);
    signature.current.clear();
  }

  const handleShowSignaturePad = (e) => {
    setSignatureShow(true);
  }

  
  async function formSubmit(e) {
    if (signature.current.getTrimmedCanvas().toDataURL('image/png')) {
      //console.log(formData);
      e.preventDefault();
      const response = await fetch("https://app.4pointtkd-members.com/api/application/"+applicationCode+"/sign", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrer: "client",
        body: JSON.stringify({media_release_signature: signature.current.getTrimmedCanvas().toDataURL('image/png'), code: applicationCode}),
      });

      const json = await response.json();
      //console.log(json);
      if (json.status == "Success") {
        // history.push("/thank-you-for-signing-up");
        alert("Signature updated successfully!");
      }
    } else {
      alert("Please add signature!");
    }
  }

  const retrieveApplicant = async () => {
    //const response = fetch(`https://adrienb1.sg-host.com/api/account`, {
    const response = fetch('https://app.4pointtkd-members.com/api/application/'+applicationCode, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  useEffect(() => {
    const getApplicant = async () => {
      const selectedApplicant = await retrieveApplicant();
      //console.log(selectedApplicants);
      console.log(selectedApplicant);
      if (selectedApplicant) {
        setApplicant(selectedApplicant);
      }
    };
    getApplicant();
  }, []);

  return (
    <>
      <Container>
        <div className="row signature-container">
          <div className="col-lg-12">
            <h2 className="mb-3">{applicant ? applicant.first_name : null } {applicant ? applicant.last_name : null }</h2>
            <h3>Signature</h3>
            {applicant ?
              <>
                {applicant.media_release_signature != "" ?
                  <>
                    {signatureShow ?
                      <SignaturePad
                        ref={ signature }
                        canvasProps={{
                          width: 630, height: 200, className: "signatureCanvas"
                        }}
                      />
                      :
                      <div className="signature-container text-center update-signature">
                        <img src={applicant.media_release_signature} onClick={handleShowSignaturePad} />
                      </div>
                    }
                  </>
                  :
                  <SignaturePad
                    ref={ signature }
                    canvasProps={{
                      width: 630, height: 200, className: "signatureCanvas"
                    }}
                  />
                }
              </>
              :
              <>
                null
              </>
            }
            
            <div className="text-right">
              <button type="button" className="clear-btn" onClick={clearSignature} >Clear</button>
              <button type="button" className="save-btn" onClick={saveSignature}>Save</button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default SignatureForm