import Meta from '../components/Meta'
import RegistrationForm from '../components/Forms/RegistrationForm'

const Register = () => {
  // page content
  const pageTitle = 'Registration'
  //const pageDescription = 'Register to 4 point Taekwondo'

  return (
    <div>
        <Meta title={pageTitle}/>
        {/*}<Header head={pageTitle} description={pageDescription} />*/}
        <RegistrationForm />
    </div>
  )
}

export default Register
