import Meta from '../components/Meta'
import AllCategories from '../components/AllCategories'
import '../css/ApplicationStyles.css'

const Categories = () => {
  const pageTitle = 'Categories'

  return (
    <div id="categories-page">
      <Meta title={pageTitle} />
      <AllCategories />
    </div>
  )
}

export default Categories
