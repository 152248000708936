import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import SignaturePad from "react-signature-canvas";
import validator from 'validator'
import './RegistrationFormStyles.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Modal from 'react-bootstrap/Modal'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalDialog from 'react-bootstrap/ModalDialog'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'

import { BsFillPersonFill } from 'react-icons/bs'

const RegistrationForm = () => {
    let history = useHistory();
    const [step, setStep] = useState(1);
    const [applicantAge, setApplicantAge] = useState(0);
    const [emailError, setEmailError] = useState(false);
    const [modalClass, setModalClass] = useState("modal fade");

    const [consent, setConsent] = useState(false);

    const [signatureUrl, setSignatureUrl] = useState(null); // create a state that will contain our image url
    const [media_release_signatureUrl, setmedia_release_signatureUrl] = useState(null); // create a state that will contain our image url
    const signature = useRef({});
    const media_release_signature = useRef({});
    const clearSignature = (e) => {
		signature.current.clear();
		setFormData({
          ...formData,
          signature: "",
        });
	}
    const clearmedia_release_signature = (e) => {
		media_release_signature.current.clear();
		setFormData({
			...formData,
			signature: "",
		});
	}
    const saveSignature = (e) => {
        setSignatureUrl(signature.current.getTrimmedCanvas().toDataURL("image/png"));
        setFormData({
          ...formData,
          signature: signature.current.getTrimmedCanvas().toDataURL("image/png")
        });
        setModalClass("modal fade");
    }

    const savemedia_release_signature = (e) => {
        setmedia_release_signatureUrl(media_release_signature.current.getTrimmedCanvas().toDataURL("image/png"));
        setFormData({
          ...formData,
          media_release_signature: media_release_signature.current.getTrimmedCanvas().toDataURL("image/png"),
          media_release: '1'
        });
        setModalClass("modal fade");
    }

    const showModal = (e) => {
        setModalClass("modal fade show");
    }

    const hideModal = (e) => {
        setModalClass("modal fade");
    }

    var curr = new Date();
    curr.setDate(curr.getDate());
    var today = curr.toISOString().substr(0,10);

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        birth_date: today,
        email_address: "",
        address: "",
        gender: "Male",
        city: "",
        postal_code: "",
        phone_number: "",
        applicantSignature: "",
        notifications: "",
        guardian_first_name: "",
        guardian_last_name: "",
        guardian_phone_number: "",
        guardian_relationship: "",
        pregnant_flag: "",
        illness_flag: "",
        illness_description: "",
        medications_flag: "",
        medications_description: "",
        injuries_flag: "",
        injuries_description: "",
        concussion_flag: "",
        concussion_description: "",
        others_flag: "",
        others_description: "",
        goal: "",
        reference: "",
        signature: "",
        media_release_signature: "",
        signee: "",
        media_release: "",
        disclaimer: ""
    });

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      //console.log(formData);
    };

    const testSubmit = (e) => {
        console.log(formData);
    }

    // const nextStep = (e) => {
    //   setStep(step + 1);
    // }

    const nextStep1 = (e) => {
        var email = formData.email_address;
        if (validator.isEmail(email)) {

        } else {
            alert("Please enter a valid email address");
        }
        if (validator.isEmail(email) && formData.first_name != "" && formData.last_name != "" && formData.address != "" && formData.city != "" && formData.postal_code != "" && formData.phone_number != "" && formData.birth_date != "" && formData.notifications != "") {
            setStep(step + 1);
            //setEmailError(false);
        } else {
            alert("Please fill up Applicant details");
            //setEmailError(true);
        }
    };

    const nextStep2 = (e) => {
        if (formData.guardian_first_name != "" && formData.guardian_last_name != "" && formData.guardian_phone_number != "" && formData.guardian_relationship != "" && formData.gender == "Female") {
            setStep(step + 1);
        } else if (formData.guardian_first_name != "" && formData.guardian_last_name != "" && formData.guardian_phone_number != "" && formData.guardian_relationship != "" && formData.gender == "Male") {
            setStep(step + 2);
        } else {
            alert("Please fill up Guardian details");
        }
        console.log(step);
    };

    const nextStep3 = (e) => {
        if (formData.pregnant_flag != "") {
            setStep(step + 1);
        } else {
            alert("Please select atleast 1");
        }
    };

    const nextStep4 = (e) => {
        if (formData.illness_flag != "") {
            if (formData.illness_flag == "1" && formData.illness_description == "") {
                alert("Please fill up all fields");
            } else {
                setStep(step + 1);
            }
        } else {
            alert("Please select atleast 1");
        }
    };

    const nextStep5 = (e) => {
        if (formData.medications_flag != "") {
            if (formData.medications_flag == "1" && formData.medications_description == "") {
                alert("Please fill up all fields");
            } else {
                setStep(step + 1);
            }
        } else {
            alert("Please select atleast 1");
        }
    };

    const nextStep6 = (e) => {
        if (formData.injuries_flag != "") {
            if (formData.injuries_flag == "1" && formData.injuries_description == "") {
                alert("Please fill up all fields");
            } else {
                setStep(step + 1);
            }
        } else {
            alert("Please select atleast 1");
        }
    };

    const nextStep7 = (e) => {
        if (formData.concussion_flag != "") {
            if (formData.concussion_flag == "1" && formData.concussion_description == "") {
                alert("Please fill up all fields");
            } else {
                setStep(step + 1);
            }
        } else {
            alert("Please select atleast 1");
        }
    };

    const nextStep8 = (e) => {
        if (formData.others_flag != "") {
            if (formData.others_flag == "1" && formData.others_description == "") {
                alert("Please fill up all fields");
            } else {
                setStep(step + 1);
            }
        } else {
            alert("Please select atleast 1");
        }
    };

    const nextStep9 = (e) => {
        if (formData.goal != "") {
            setStep(step + 1);
        } else {
            alert("Please fill up all fields");
        }
    };

    const nextStep10 = (e) => {
        if (formData.reference != "") {
            setStep(step + 1);
        } else {
            alert("Please fill up all fields");
        }
        var birth_date = new Date(formData.birth_date).getFullYear();
        var currentDate = new Date().getFullYear();
        var applicantAge = currentDate - birth_date;
        setApplicantAge(applicantAge);
        if (applicantAge > 17) {
            setFormData({
              ...formData,
              signee: formData.guardian_first_name +" "+ formData.guardian_last_name
            });
        } else {
            setFormData({
              ...formData,
              signee: formData.first_name +" "+ formData.last_name
            });
        }
    };

    const nextStep11 = (e) => {
        if (formData.signature != "") {
            setStep(step + 1);
        } else {
            alert("Please fill up all fields and Sign the waiver");
        }
    };

    const handleMediaRelease = (e) => {
        const media_release_value = e.target.value;
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
		console.log(formData);
        if (media_release_value == "1") {
            setConsent(true);
            setModalClass("modal fade show");
            //displays signature pad
        } else {
            setConsent(false);
            setFormData({
                ...formData,
                media_release_signature: "",
				media_release: "0",
            });
            //hide signature pad
        }
    }

    async function formSubmit(e) {
        if ((formData.media_release === "1" && formData.media_release_signature != "") || formData.media_release === "0") {
            //console.log(formData);
            e.preventDefault();
            const response = await fetch("https://app.4pointtkd-members.com/api/application", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrer: "client",
            body: JSON.stringify(formData),
            });

            const json = await response.json();
            //console.log(json);
            if (json.status == "Success") {
                history.push("/thank-you-for-signing-up");
            }
        } else {
			alert("Please select atleast 1 and Sign the media waiver");
		}
    }

    const prevStep = (e) => {
      setStep(step - 1);
    };

    const prevStepPregnant = (e) => {
        if (formData.gender == "Female") {
            setStep(step - 1);
        } else {
            setStep(step - 2);
        }
        //console.log(step);
    };

    return (
        <Container id="registration-form" className="card">
            {/*}{step=== 0 ?
                <div className="row">
                  <div className="col-lg-12 text-center">
                      <h2>LOGO HERE</h2>
                  </div>
                  <div className="col-lg-12 text-center">
                      <button className="btn-form-navi" onClick={nextStep}>Start</button>
                  </div>
                </div>
                :
                null
            }*/}
          {/*<div className="row">
              <div className="col-lg-12">
                  <h2 className="text-center">
                      Step {step} of 11
                  </h2>
              </div>
          </div>*/}
            {step === 1 ?
                <>
                    <div className="row card-body" id="registration-step-1">

                        <div className="col-lg-12">
                            <ProgressBar animated now={0} label={'0%'}/>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="registration-form-header"><BsFillPersonFill className="person-icon"/>Application Information</h2>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">First Name</label>
                            <input type="text" onChange={handleChange} className="form-control" placeholder="First Name" name="first_name" id="first_name" value={formData.first_name} required />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Last Name</label>
                            <input type="text" onChange={handleChange} className="form-control" placeholder="Last Name" name="last_name" id="last_name" value={formData.last_name} required />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Birth Date</label>
                            <input type="date" onChange={handleChange} className="form-control" name="birth_date" id="birth_date" value={formData.birth_date} required />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Gender</label>
                          <div className="select">
                            <select onChange={handleChange} value={formData.gender} className="select-input" name="gender">
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                            <label className="form-label">Contact Information</label>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Phone Number</label>
                            <input type="tel" onChange={handleChange} className="form-control" placeholder="Phone Number" name="phone_number" id="phone_number" value={formData.phone_number} required />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Email Address</label>
                            <input type="text" onChange={handleChange} className="form-control" placeholder="Email Address" name="email_address" id="email_address" value={formData.email_address} required />
                            {emailError ?
                                <div className="alert alert-danger pt-1 pb-1">
                                    Please insert a valid email address.
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className="col-lg-12">
                            <label className="form-label">Address Information</label>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Address</label>
                            <input type="text" onChange={handleChange} className="form-control" placeholder="Address" name="address" id="address" value={formData.address} required />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">City</label>
                            <input type="text" onChange={handleChange} className="form-control" placeholder="City" name="city" id="city" value={formData.city} required />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Postal Code</label>
                            <input type="text" onChange={handleChange} className="form-control" placeholder="Postal Code" name="postal_code" id="postal_code" value={formData.postal_code} required />
                        </div>
                        <div className="col-lg-12 mb-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p id="notifications-header">Would you like to be notified via email regarding special events and promotions?</p>
                                </div>
                                <div className="col-lg-2 radio-buttons-container">
                                    {formData.notifications === "1" ?
                                        <>
                                            <input type="radio" name="notifications" onChange={handleChange} value="1" checked/>  Yes
                                        </>
                                        :
                                        <>
                                            <input type="radio" name="notifications" onChange={handleChange} value="1"/>  Yes
                                        </>
                                    }
                                </div>
                                <div className="col-lg-2 radio-buttons-container">
                                    {formData.notifications === "0" ?
                                        <>
                                            <input type="radio" name="notifications" onChange={handleChange} value="0" checked/>  No
                                        </>
                                        :
                                        <>
                                            <input type="radio" name="notifications" onChange={handleChange} value="0"/>  No
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row card-footer">
                        <div className="col-lg-12">
                            <div className="row justify-content-center form-navi-container">
                                <div className="col-lg-9"></div>
                                <div className="col-lg-3">
                                   <button className="btn-form-navi" onClick={() => nextStep1()}>NEXT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                null
            }
            {step === 2 ?
                <>

                    <div className="row card-body" id="registration-step-2">
                        <div className="col-lg-12">
                            <ProgressBar animated now={18} label={'18%'}/>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="registration-form-header">Parent / Guardian Emergency Contact</h2>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">First Name</label>
                            <input type="text" onChange={handleChange} className="form-control" placeholder="First Name" name="guardian_first_name" id="guardian_first_name" value={formData.guardian_first_name} required />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Last Name</label>
                            <input type="text" onChange={handleChange} className="form-control" placeholder="Last Name" name="guardian_last_name" id="guardian_last_name" value={formData.guardian_last_name} required />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Phone Number</label>
                            <input type="tel" onChange={handleChange} className="form-control" placeholder="Phone" name="guardian_phone_number" id="guardian_phone_number" value={formData.guardian_phone_number} required />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="mobile-label">Relationship</label>
                            <input type="text" onChange={handleChange} className="form-control" placeholder="Relationship" name="guardian_relationship" id="guardian_relationship" value={formData.guardian_relationship} required />
                        </div>
                    </div>
                    <div className="row card-footer">
                        <div className="col-lg-12">
                            <div className="row justify-content-center form-navi-container">
                                <div className="col-lg-3">
                                    <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                </div>
                                <div className="col-lg-6"></div>
                                <div className="col-lg-3">
                                    <button className="btn-form-navi" onClick={() => nextStep2()}>NEXT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                null
            }

            {step > 2 ?
                <div>
                    {step === 3 && formData.gender == "Female" ?
                        <>
                            <div className="row card-body" id="registration-step-3">
                                <div className="col-lg-12">
                                    <ProgressBar animated now={27} label={'27%'}/>
                                </div>
                                <div className="col-lg-12">
                                    <p className="radio-buttons-header">Are you currently pregnant?</p>
                                    <div className="row mb-4">
                                        <div className="col-lg-2">
                                            {formData.pregnant_flag == "1" ?
                                                <>
                                                    <input type="radio" onChange={handleChange} name="pregnant_flag" value="1" checked/> Yes
                                                </>
                                                :
                                                <>
                                                    <input type="radio" onChange={handleChange} name="pregnant_flag" value="1" /> Yes
                                                </>
                                            }
                                        </div>
                                        <div className="col-lg-2">
                                            {formData.pregnant_flag == "0" ?
                                                <>
                                                    <input type="radio" onChange={handleChange} name="pregnant_flag" value="0" checked/> No
                                                </>
                                                :
                                                <>
                                                    <input type="radio" onChange={handleChange} name="pregnant_flag" value="0" /> No
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row card-footer">
                                <div className="col-lg-12">
                                    <div className="row justify-content-center form-navi-container">
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => nextStep3()}>NEXT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null
                    }

                    {step === 4 ?
                        <>
                            <div className="row card-body" id="registration-step-4">
                                <div className="col-lg-12">
                                    <ProgressBar animated now={36} label={'36%'}/>
                                </div>
                                <div className="col-lg-12">
                                    <p className="radio-buttons-header">Do you suffer from any chronic or serious illness?</p>
                                    <div className="row mb-4">
                                        <div className="col-lg-2">
                                            {formData.illness_flag == "1" ?
                                                <>
                                                    <input type="radio" onChange={handleChange} name="illness_flag" value="1" checked /> Yes
                                                </>
                                                :
                                                <>
                                                    <input type="radio" onChange={handleChange} name="illness_flag" value="1" /> Yes
                                                </>
                                            }
                                        </div>
                                        <div className="col-lg-2">
                                            {formData.illness_flag == "0" ?
                                                <>
                                                    <input type="radio" onChange={handleChange} name="illness_flag" value="0" checked /> No
                                                </>
                                                :
                                                <>
                                                    <input type="radio" onChange={handleChange} name="illness_flag" value="0" /> No
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {formData.illness_flag == "1" ?
                                        <>
                                            <p>Please specify</p>
                                            <textarea onChange={handleChange} name="illness_description" value={formData.illness_description} className="form-control mb-4"></textarea>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="row card-footer">
                                <div className="col-lg-12">
                                    <div className="row justify-content-center form-navi-container">
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => prevStepPregnant()}>PREVIOUS</button>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => nextStep4()}>NEXT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null
                    }

                    {step === 5 ?
                        <>
                            <div className="row card-body" id="registration-step-5">
                                <div className="col-lg-12">
                                    <ProgressBar animated now={45} label={'45%'}/>
                                </div>
                                <div className="col-lg-12">
                                    <p className="radio-buttons-header">Are you currently taking any medications?</p>
                                    <div className="row mb-4">
                                        <div className="col-lg-2">
                                            {formData.medications_flag == "1" ?
                                                <>
                                                    <input type="radio" onChange={handleChange} name="medications_flag" value="1" checked /> Yes
                                                </>
                                                :
                                                <>
                                                    <input type="radio" onChange={handleChange} name="medications_flag" value="1" /> Yes
                                                </>
                                            }
                                        </div>
                                        <div className="col-lg-2">
                                            {formData.medications_flag == "0" ?
                                                <>
                                                    <input type="radio" onChange={handleChange} name="medications_flag" value="0" checked /> No
                                                </>
                                                :
                                                <>
                                                    <input type="radio" onChange={handleChange} name="medications_flag" value="0" /> No
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {formData.medications_flag == "1" ?
                                        <>
                                            <p>Please specify</p>
                                            <textarea onChange={handleChange} name="medications_description" value={formData.medications_description} className="form-control mb-4"></textarea>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="row card-footer">
                                <div className="col-lg-12">
                                    <div className="row justify-content-center form-navi-container">
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => nextStep5()}>NEXT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null
                    }

                        {step === 6 ?
                            <>
                                <div className="row card-body" id="registration-step-6">
                                    <div className="col-lg-12">
                                        <ProgressBar animated now={54} label={'54%'}/>
                                    </div>
                                    <div className="col-lg-12">
                                        <p className="radio-buttons-header">Are you experiencing any chronic pain/ injuries?</p>
                                        <div className="row mb-4">
                                            <div className="col-lg-2">
                                                {formData.injuries_flag == "1" ?
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="injuries_flag" value="1" checked /> Yes
                                                    </>
                                                    :
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="injuries_flag" value="1" /> Yes
                                                    </>
                                                }
                                            </div>
                                            <div className="col-lg-2">
                                                {formData.injuries_flag == "0" ?
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="injuries_flag" value="0" checked /> No
                                                    </>
                                                    :
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="injuries_flag" value="0" /> No
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {formData.injuries_flag == "1" ?
                                            <>
                                                <p>Please specify</p>
                                                <textarea onChange={handleChange} name="injuries_description" value={formData.injuries_description} className="form-control mb-4"></textarea>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div className="row card-footer">
                                    <div className="col-lg-12">
                                        <div className="row justify-content-center form-navi-container">
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                            </div>
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => nextStep6()}>NEXT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }

                        {step === 7 ?
                            <>
                                <div className="row card-body" id="registration-step-7">
                                    <div className="col-lg-12">
                                        <ProgressBar animated now={63} label={'63%'}/>
                                    </div>
                                    <div className="col-lg-12">
                                        <p className="radio-buttons-header">Have you ever suffered a concussion?</p>
                                        <div className="row mb-4">
                                            <div className="col-lg-2">
                                                {formData.concussion_flag == "1" ?
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="concussion_flag" value="1" checked /> Yes
                                                    </>
                                                    :
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="concussion_flag" value="1" /> Yes
                                                    </>
                                                }

                                            </div>
                                            <div className="col-lg-2">
                                                {formData.concussion_flag == "0" ?
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="concussion_flag" value="0" checked /> No
                                                    </>
                                                    :
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="concussion_flag" value="0" /> No
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {formData.concussion_flag == "1" ?
                                            <>
                                                <p>Please specify</p>
                                                <textarea onChange={handleChange} name="concussion_description" className="form-control mb-4" value={formData.concussion_description} ></textarea>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div className="row card-footer">
                                    <div className="col-lg-12">
                                        <div className="row justify-content-center form-navi-container">
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                            </div>
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => nextStep7()}>NEXT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }

                        {step === 8 ?
                            <>
                                <div className="row card-body" id="registration-step-8">
                                    <div className="col-lg-12">
                                        <ProgressBar animated now={72} label={'72%'}/>
                                    </div>
                                    <div className="col-lg-12">
                                        <p className="radio-buttons-header">Do you have any other medical conditions(s) we should be aware of?</p>
                                        <div className="row mb-4">
                                            <div className="col-lg-2">
                                                {formData.others_flag == "1" ?
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="others_flag" value="1" checked /> Yes
                                                    </>
                                                    :
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="others_flag" value="1" /> Yes
                                                    </>
                                                }
                                            </div>
                                            <div className="col-lg-2">
                                                {formData.others_flag == "0" ?
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="others_flag" value="0" checked /> No
                                                    </>
                                                    :
                                                    <>
                                                        <input type="radio" onChange={handleChange} name="others_flag" value="0" /> No
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {formData.others_flag == "1" ?
                                            <>
                                                <p>Please specify</p>
                                                <textarea onChange={handleChange} name="others_description" className="form-control mb-4" value={formData.others_description} ></textarea>
                                            </>
                                            :
                                            null
                                        }
                                    </div>

                                </div>
                                <div className="row card-footer">
                                    <div className="col-lg-12">
                                        <div className="row justify-content-center form-navi-container">
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                            </div>
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => nextStep8()}>NEXT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }

                        {step === 9 ?
                            <>
                                <div className="row card-body" id="registration-step-8">
                                    <div className="col-lg-12">
                                        <ProgressBar animated now={81} label={'81%'}/>
                                    </div>
                                    <div className="col-lg-12">
                                        <p className="radio-buttons-header">What are you looking to get out of martial arts?</p>
                                        <textarea onChange={handleChange} value={formData.goal} name="goal" className="form-control mb-4"></textarea>
                                    </div>
                                </div>
                                <div className="row card-footer">
                                    <div className="col-lg-12">
                                        <div className="row justify-content-center form-navi-container">
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                            </div>
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => nextStep9()}>NEXT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }

                        {step === 10 ?
                            <>
                                <div className="row card-body" id="registration-step-8">
                                    <div className="col-lg-12">
                                        <ProgressBar animated now={90} label={'90%'}/>
                                    </div>
                                    <div className="col-lg-12">
                                        <p className="radio-buttons-header">How did you hear about us?</p>
                                        <textarea onChange={handleChange} value={formData.reference} name="reference" className="form-control mb-4"></textarea>
                                    </div>
                                </div>
                                <div className="row card-footer">
                                    <div className="col-lg-12">
                                        <div className="row justify-content-center form-navi-container">
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                            </div>
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-3">
                                                <button className="btn-form-navi" onClick={() => nextStep10()}>NEXT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }
                    </div>
                :
                null
            }
            {step === 11 ?
                <div>
                    {applicantAge > 17 ?
                        <>
                            <div className="row card-body justify-content-center waiver">
                                <div className="col-lg-12">
                                    <ProgressBar animated now={95} label={'95%'}/>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {/*The waiver body should be here for 18 up*/}
                                            <h4>Acknowledgement of Risk and Release of Liability (18+)</h4>
                                            <p>For Students over the Age of Majority in the Province or Territory in which the Martial Arts Activities are provided by the Organization</p>
                                            <br/>
                                            <h5>WARNING: THIS AGREEMENT WILL AFFECT YOUR LEGAL RIGHTS. READ IT CAREFULLY!</h5>
                                            <br/>
                                            <p>Every Student Must Read and Understand this Waiver before Participating in Martial Arts Activities</p>
                                            <br/>
                                            <p>The following waiver of all claims, release from all liability, assumption of all risks, agreement not to sue and other terms of this agreement are entered into by me on behalf of the undersigned Student</p>
                                            <p>(the "Student") with and for the benefit of 4Point Taekwondo Inc., its directors, officers, employees, volunteers, business operators, agents and site property owners or lessees (the "Organization").</p>
                                            <p>1. "Martial Arts Activities" includes but is not limited to contact and non-contact martial arts activities, lessons, classes, training, use of facilities, programs and services provided to the Student by the Organization.</p>
                                            <p>2. I am aware that there are inherent and significant dangers, hazards and risks (“Risks') associated with the participation in Martial Arts Activities. I am aware that the Risks include but are not limited to injury from physical contact with other students, instructors or equipment performing a skill incorrectly, or potentially dangerous obstacles, conditions or weapons on the floor or vicinity of the Martial Arts Activities. I understand that the Risks are relative to my state of fitness or health (physical, mental and emotional), and to the awareness, care and skill with which the student conducts him or herself while participating in Martial Arts Activities.</p>
                                            <p>3. I freely accept and fully assume all responsibility for all Risks and possibilities of personal injury, death, property damage or loss resulting from my participation in Martial Arts Activities. I freely assume responsibility for my own safety. I agree that although the Organization has taken steps to reduce the Risks and increase the safety of the Martial Arts Activities, it is not possible for the Organization to make the Martial Arts Activities completely safe. I accept these Risks and agree to the terms of this waiver even if the Organization is found to be negligent or in breach of any duty of care or any obligation to me in my participation in Martial Arts Activities.</p>
                                            <p>4. I acknowledge my obligation to inform the nearest employee of the Organization if I feel any pain, discomfort, fatigue or any other symptoms I may suffer during or immediately after my participation in Martial Arts Activities. I understand that I may stop participating at any time, and have the right to immediately withdraw from any exercise or drill in which the conduct of any party seems beyond the scope of training, makes me uncomfortable or which I believe will be harmful to me.</p>
                                            <p>5. I confirm that I have reached the age of majority in the Province or Territory in which I am participating in Martial Arts Activities.</p>
                                            <p>6. In addition to consideration given to the Organization for my participation in Martial Arts Activities, I and my heirs, next of kin, executors, administrators and assigns (my "Legal Representatives") agree:</p><p> </p><p>(a) to waive all claims that I or my Legal Representatives have or may have in the future</p><p>against the Organization; and</p><p> </p><p>(b) to release and forever discharge the Organization from all liability for all personal injury,</p><p>death, property damage or loss resulting from my participation in Martial Arts Activities due to any cause, including but not limited to negligence (failure to use such care as a reasonably prudent and careful person would use under similar circumstances), breach of any duty imposed by law, breach of contract or mistake or error in judgment of the Organization.</p>
                                            <p>7. I agree to be liable for and to hold harmless and indemnify the Organization from all actions, proceedings, claims, damages, costs demands including court costs and costs on a solicitor and own client basis, and liabilities of whatsoever nature or kind arising out of or in any way connected with my participation in Martial Arts Activities.</p>
                                            <p>8. I agree that this waiver and all terms contained within are governed by the laws of the Province or Territory in which I am participating in Martial Arts Activities. I hereby irrevocably submit to the jurisdiction of the courts of that Province or Territory.</p>
                                            <p>9. I confirm that I have had sufficient time to read and understand each term in this waiver in its entirety, and have agreed to the terms freely and voluntarily. I understand that this waiver is binding on myself and my Legal Representatives.</p>
                                        </div>
                                    </div>
                                    <h3>
                                        Participant's Name
                                    </h3>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="text" className="form-control" value={formData.first_name} />
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="text" className="form-control" value={formData.last_name} />
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="tel" className="form-control" value={formData.phone_number} />
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <select className="form-control text-capitalize" value={formData.gender}>
                                                <option value={formData.gender}>{formData.gender}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <h3>
                                        Participant's Date of Birth
                                    </h3>
                                    <div className="row justify-content-center">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <input type="date" className="form-control" value={formData.birth_date} />
                                        </div>
                                    </div>
                                    <h3>
                                        Participant's Signature
                                    </h3>

                                    <div className="row">
                                        <div className="col-lg-3">
                                            <button type="button" onClick={showModal} className="btn-form-navi" data-toggle="modal" data-target="#exampleModal">
                                              Click to Sign
                                            </button>
                                        </div>
                                    </div>

                                    <div className={modalClass} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Participant's Signature</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={hideModal} >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <SignaturePad
                                                                ref={signature}
                                                                canvasProps={{
                                                                    width: 450, height: 200, className: "signatureCanvas"
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={clearSignature} >Clear</button>
                                                    <button type="button" className="btn btn-primary" onClick={saveSignature}>Save Signature</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row card-footer">
                                <div className="col-lg-12">
                                    <div className="row justify-content-center form-navi-container">
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => nextStep11()}>NEXT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="row card-body justify-content-center waiver">
                                <div className="col-lg-12">
                                    <ProgressBar animated now={95} label={'95%'}/>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {/*The waiver body should be here for 17 below*/}
                                            <h4>Acknowledgement of Risk and Release of Liability (Under 18)</h4>
                                            <p>For Students under the Age of Majority in the Province or Territory in which the Martial Arts Activities are provided by the Organization</p>
                                            <br/>
                                            <h5>WARNING: THIS AGREEMENT WILL AFFECT YOUR LEGAL RIGHTS. READ IT CAREFULLY!</h5>
                                            <br/>
                                            <p>Every Parent/Guardian Must Read and Understand this Waiver Prior to the Student Participating in Martial Arts Activities</p>
                                            <br />
                                            <p>The following waiver of all claims, release from all liability, assumption of all risks, agreement not to sue and other terms of this agreement are entered into by me on behalf of the undersigned Student (the "Student") with and for the benefit of 4Point Taekwondo Inc., its directors, officers, employees, volunteers, business operators, agents and site property owners or lessees (the "Organization").</p>
                                            <p>1. I am the Parent/Guardian of the Student and am executing this waiver on behalf of the Student in my capacity as Parent/Guardian and with the intent that this waiver be binding on myself and the Student for all purposes.</p>
                                            <p>2. "Martial Arts Activities" includes but is not limited to contact and non-contact martial arts activities, lessons, classes, training, use of facilities, programs and services provided to the Student by the Organization.</p>
                                            <p>3. I am aware that there are inherent and significant dangers, hazards and risks (“Risks") associated with the participation in Martial Arts Activities. I am aware that the Risks include but are not limited to injury from physical contact with other students, instructors or equipment, performing a skill incorrectly, or potentially dangerous obstacles, conditions or weapons on the floor or vicinity of the Martial Arts Activities. I understand that the Risks are relative to the Student's state of fitness or health (physical, mental and emotional), and to the awareness, care and skill with which the student conducts him or herself while participating in Martial Arts Activities.</p>
                                            <p>4. I freely accept and fully assume all responsibility for all Risks and possibilities of personal injury, death, property damage or loss resulting from the Student's participation in Martial Arts Activities. I agree that although the Organization has taken steps to reduce the Risks and increase the safety of the Martial Arts Activities, it is not possible for the Organization to make the Martial Arts Activities completely safe. I accept these Risks and agree to the terms of this waiver even if the Organization is found to be negligent or in breach of any duty of care or any obligation to the Student in the Student's participation in Martial Arts Activities.</p>
                                            <p>5. I acknowledge the Student's obligation to inform the nearest employee of the Organization if the Student feels any pain, discomfort, fatigue or any other symptoms the Student may suffer during or immediately after his or her participation in Martial Arts Activities. I understand that the Student may stop participating at any time, and has the right to immediately withdraw from any exercise or drill in which the conduct of any party seems beyond the scope of training, makes the Student uncomfortable or which the Student believes will be harmful to him or her.</p>
                                            <p>6. In addition to consideration given to the Organization for the Student's participation in  Martial Arts Activities, I and my heirs, next of kin, executors, administrators and assigns, as well as the Student's heirs, next of kin, executors, administrators and assigns (collectively our “Legal Representatives") agree:</p><p> </p><p>(a) to waive all claims that I, the Student or our Legal Representatives have or may have in the</p><p>future against the Organization; and</p><p> </p><p>(b) to release and forever discharge the Organization from all liability for all personal injury, death, property damage or loss resulting from the Student's participation in Martial Arts Activities due to any cause, including but not limited to negligence (failure to use such care as a reasonably prudent and careful person would use under similar circumstances), breach of any duty imposed by law, breach of contract or mistake or error in judgment of the Organization.</p>
                                            <p>7. I agree to be liable for and to hold harmless and indemnify the Organization from all actions, proceedings, claims, damages, costs demands including court costs and costs on a solicitor and own client basis, and liabilities of whatsoever nature or kind arising out of or in any way connected with the Student's participation in Martial Arts Activities.</p>
                                            <p>8. I agree that this waiver and all terms contained within are governed by the laws of the Province or Territory in which the Student is participating in Martial Arts Activities. I hereby irrevocably submit to the jurisdiction of the courts of that Province or Territory.</p>
                                            <p>9. I confirm that I have had sufficient time to read and understand each term in this waiver in its entirety, and have agreed to the terms freely and voluntarily. I understand that this waiver is binding on myself, the Student and our Legal Representatives.</p>

                                        </div>
                                    </div>
                                    <h3>
                                        Participant's Name
                                    </h3>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="text" className="form-control" value={formData.first_name} readOnly/>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="text" className="form-control" value={formData.last_name} readOnly/>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="tel" className="form-control" value={formData.phone_number} readOnly/>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <select className="form-control text-capitalize" value={formData.gender} readOnly>
                                                <option value={formData.gender}>{formData.gender}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <h3>
                                        Participant's Date of Birth
                                    </h3>
                                    <div className="row justify-content-center">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <input type="date" className="form-control" value={formData.birth_date} readOnly/>
                                        </div>
                                    </div>
                                    

                                    <h3>
                                        Parent or Guardian's Name
                                    </h3>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="text" className="form-control" value={formData.guardian_first_name} readOnly/>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="text" className="form-control" value={formData.guardian_last_name} readOnly/>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="tel" className="form-control" value={formData.guardian_phone_number} readOnly/>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <input type="text" className="form-control" value={formData.guardian_relationship} readOnly/>
                                        </div>
                                    </div>
                                    
                                    <h3>
                                        Parent or Guardian's Signature
                                    </h3>

                                    <div className="row">
                                        <div className="col-lg-3">
                                            <button type="button" onClick={showModal} className="btn-form-navi" data-toggle="modal" data-target="#exampleModal">
                                              Click to Sign
                                            </button>
                                        </div>
                                    </div>

                                    <div className={modalClass} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Parent or Guardian's Signature</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={hideModal} >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <SignaturePad
                                                                ref={signature}
                                                                canvasProps={{
                                                                    width: 450, height: 200, className: "signatureCanvas"
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={clearSignature} >Clear</button>
                                                    <button type="button" className="btn btn-primary" onClick={saveSignature}>Save Signature</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row card-footer">
                                <div className="col-lg-12">
                                    <div className="row justify-content-center form-navi-container">
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-3">
                                            <button className="btn-form-navi" onClick={() => nextStep11()}>NEXT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                 :
                 null
            }
            {step === 12 ?
                <>
                    <div className="row card-body justify-content-center waiver">
                        <div className="col-lg-12">
                            <ProgressBar animated now={100} label={'100%'}/>
                        </div>
                        <div className="col-lg-12">
                            <div className="card">
                              <div className="card-body">
                                <p><strong>PHOTO AND VIDEO CONSENT, ASSIGNMENT AND RELEASE FORM</strong></p>
                                <p>PURPOSES: For marketing, advertising, promotional and/or communication purposes, 4Point Taekwondo, from time to time, take photographs and/or video recordings of 4Point Taekwondo based activities or events that include real people, which photographs and video recordings will be placed in the 4Point Taekwondo Photo Bank and which may be used by 4Point Taekwondo, for its own informational, promotional or advertising purposes. For purposes of this Form, “4Point Taekwondo” refers to 4Point Taekwondo Inc. in Canada.</p>
                                <p>By signing this Form, you are consenting to the taking of photographs and/or video recordings of you by 4Point Taekwondo for the Purposes, you are assigning to 4Point Taekwondo, and waiving any rights you have related to, any such photographs and/or video recordings, and you are consenting to the use of any such photographs and/or video recordings, in whole or in part, by 4Point Taekwondo.</p>
                                <p>For valuable consideration received but without any promise of remuneration, I hereby agree to allow photographs and/or video recordings to be taken of me, whether posed or candid, while I am on 4Point Taekwondo property and/or participating in 4Point Taekwondo activities or events, to be reproduced, published, displayed, broadcast, transmitted, licensed, sublicensed or otherwise used by 4Point Taekwondo  in connection with the Purposes, including without limitation on 4Point Taekwondo internet web sites, in 4Point Taekwondo printed materials, or in any other materials or medium whatsoever and wherever (the “Work Product”). I confirm that neither 4Point Taekwondo shall be obligated to use the Work Product.</p>
                                <p>I understand that the Work Product is being created under the direction and control of the 4Point Taekwondo. I hereby irrevocably assign to 4Point Taekwondo any and all rights, including copyright, financial or other rights, and I hereby irrevocably waive in favour 4Point Taekwondo and any Authorized Third Party any and all moral rights or rights of similar nature that I may have in the Work Product. I agree that 4Point Taekwondo has the sole worldwide ownership and rights in and to the Work Product, including copyright interests, and I acknowledge that I have no interest or ownership in the Work Product or its copyright.</p>
                                <p>I understand that the Work Product is being created under the direction and control of 4Point Taekwondo. I hereby irrevocably assign to 4Point Taekwondo any and all rights, including copyright, financial or other rights, and I hereby irrevocably waive in favour of 4Point Taekwondo any and all moral rights or rights of similar nature that I may have in the Work Product. I agree that 4Point Taekwondo has the sole worldwide ownership and rights in and to the Work Product, including copyright interests, and I acknowledge that I have no interest or ownership in the Work Product or its copyright.</p>
                                <p>I confirm that I am over the age of majority in my province or territory of residence and am competent to execute this Form and to participate in the development of the Work Product; or, to the extent that I am under the age of majority in my province or territory of residence, have had my parent or guardian review this Form and consent to my participation in the creation of the Work Product on my behalf.</p><p>By signing my name, I (and my legal guardian, where applicable) acknowledge that I (or we) have carefully read and understand this Form.</p>
                                <br />

                                <p><strong>ACKNOWLEDGEMENT OF RISK AND RELEASE OF LIABILITY</strong></p>
                                <p>The following waiver of all claims, release from all liability, assumption of all risks, agreement not to sue and other terms of this agreement are entered into by me on behalf of the undersigned Student (the "Student") with and for the benefit of 4Point Taekwondo Inc., its directors, officers, employees, volunteers, business operators, agents and site property owners or lessees (the "Organization").</p>
                                <p>1. I am over the Age of Majority and am executing this waiver on behalf of myself or the Student in my capacity as Parent/Guardian&nbsp;with the intent that this waiver be binding on myself and the Student for all purposes.</p>
                                <p>2. I am aware that there are inherent and significant dangers, hazards and risks (“Risks') associated with the participation in Martial Arts Activities. I am aware that the Risks include but are not limited to injury from physical contact with other students, instructors or equipment performing a skill incorrectly, or potentially dangerous obstacles, conditions or weapons on the floor or vicinity of the Martial Arts Activities. I understand that the Risks are relative to my state of fitness or health (physical, mental and emotional), and to the awareness, care and skill with which the student conducts him or herself while participating in Martial Arts Activities.</p>
                                <p>3. I freely accept and fully assume all responsibility for all Risks and possibilities of personal injury, death, property damage or loss resulting from my participation in Martial Arts Activities. I freely assume responsibility for my own safety. I agree that although the Organization has taken steps to reduce the Risks and increase the safety of the Martial Arts Activities, it is not possible for the Organization to make the Martial Arts Activities completely safe. I accept these Risks and agree to the terms of this waiver even if the Organization is found to be negligent or in breach of any duty of care or any obligation to me in my participation in Martial Arts Activities.</p>
                                <p>4. I acknowledge my obligation to inform the nearest employee of the Organization if I feel any pain, discomfort, fatigue or any other symptoms I may suffer during or immediately after my participation in Martial Arts Activities. I understand that I may stop participating at any time, and have the right to immediately withdraw from any exercise or drill in which the conduct of any party seems beyond the scope of training, makes me uncomfortable or which I believe will be harmful to me.</p>
                                <p>5. I confirm that I have reached the age of majority in the Province or Territory in which I am participating in Martial Arts Activities.</p>
                                <p>6. In addition to consideration given to the Organization for my participation in Martial Arts Activities, I and my heirs, next of kin, executors, administrators and assigns (my "Legal Representatives") agree:</p>
                                <p>(a) to waive all claims that I or my Legal Representatives have or may have in the future</p>
                                <p>against the Organization; and</p>
                                <p>(b) to release and forever discharge the Organization from all liability for all personal injury, death, property damage or loss resulting from my participation in Martial Arts Activities due to any cause, including but not limited to negligence (failure to use such care as a reasonably prudent and careful person would use under similar circumstances), breach of any duty imposed by law, breach of contract or mistake or error in judgment of the Organization.</p>
                                <p>7. I agree to be liable for and to hold harmless and indemnify the Organization from all actions, proceedings, claims, damages, costs demands including court costs and costs on a solicitor and own client basis, and liabilities of whatsoever nature or kind arising out of or in any way connected with my participation in Martial Arts Activities.</p>
                                <p>8. I agree that this waiver and all terms contained within are governed by the laws of the Province or Territory in which I am participating in Martial Arts Activities. I hereby irrevocably submit to the jurisdiction of the courts of that Province or Territory.</p>
                                <p>9. I confirm that I have had sufficient time to read and understand each term in this waiver in its entirety, and have agreed to the terms freely and voluntarily. I understand that this waiver is binding on myself and my Legal Representatives.</p>
                              </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <p>Do you authorize us to use photo/video of yourself/your child for print and marketing purposes?</p>
                                    <div class="form-check disclaimer-checkbox">
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <input type="radio" className="form-check-input" value="1" name="media_release" onChange={handleMediaRelease} /> Yes
                                            </div>
                                            <div className="col-lg-2">
                                                <input type="radio" className="form-check-input" value="0" name="media_release" onChange={handleMediaRelease} /> No
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { consent ? 
                                <>
                                    <h3>
                                    Participant's Signature
                                </h3>

                                <div className="row">
                                    <div className="col-lg-3">
                                        <button type="button" onClick={showModal} className="btn-form-navi" data-toggle="modal" data-target="#exampleModal">
                                        Click to Sign
                                        </button>
                                    </div>
                                </div>
                                </>
                            :
                                null
                            }
                            

                            <div className={modalClass} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Participant's Signature</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={hideModal} >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <SignaturePad
                                                        ref={media_release_signature}
                                                        canvasProps={{
                                                            width: 450, height: 200, className: "signatureCanvas"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={clearmedia_release_signature} >Clear</button>
                                            <button type="button" className="btn btn-primary" onClick={savemedia_release_signature}>Save Signature</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row card-footer">
                        <div className="col-lg-12">
                            <div className="row justify-content-center form-navi-container">
                                <div className="col-lg-3">
                                    <button className="btn-form-navi" onClick={() => prevStep()}>PREVIOUS</button>
                                </div>
                                <div className="col-lg-6"></div>
                                <div className="col-lg-3">
                                    <button className="btn-form-navi" onClick={formSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                 :
                 null
            }
        </Container>
    )
}

export default RegistrationForm
