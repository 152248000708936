import Meta from '../components/Meta'
import SignatureForm from '../components/SignatureForm'
import '../css/ApplicationStyles.css'

const Application = () => {
  // page content
  const pageTitle = 'Application'
  //const pageDescription = ''

  return (
    <div id="signature-page">
      <Meta title={pageTitle}/>
      <SignatureForm />
    </div>
  )
}

export default Application
