import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BsFillPencilFill} from 'react-icons/bs'
import {FaTrash, FaPlus} from 'react-icons/fa'

const initialFormData = Object.freeze({
  id: "",
  name: "",
  created_at: ""
});

const initialAddFormData = Object.freeze({
  name: ""
});

const AllCategories = () => {
  let history = useHistory();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [applicantsCategoriesCount, setCategoriesCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [categoriesModalClass, setCategoriesModalClass] = useState("modal fade");
  const [updateCategoriesModalVisible, setUpdateCategoriesModalVisible] = useState(false);
  const [categoriesModalVisible, setCategoriesModalVisible] = useState(false);
  const [formStatus, setFormStatus] = useState("standby");
  const [reloadCount, setReloadCount] = useState(1);
  const loggedIn = sessionStorage.getItem("loggedIn");
  const userName = userData.user.name;
  const [addCategoriesModalVisible, setAddCategoriesModalVisible] = useState(false);
  const [categoriesReady, setCategoriesReady] = useState(false);
  // const { id, first_name, last_name, applicants_number } = props.applicant;

  //Add this for toast not showing
  toast.configure();
  const [formData, setFormData] = useState(initialFormData);
  const [formAddData, setAddFormData] = useState(initialAddFormData);

  const showCategoriesModal = (e) => {
    setCategoriesModalClass("modal fade applicant-modal show");
    setCategoriesModalVisible(true);
  }

  const hideCategoriesModal = (e) => {
    setCategoriesModalClass("modal fade applicant-modal");
    setShowCategoriesPrintable(false);
    setCategoriesModalVisible(false);
    setAddCategoriesModalVisible(false);
  }

  const showUpdateCategoriesModal = (e) => {
    setCategoriesModalClass("modal fade applicant-modal show");
    setUpdateCategoriesModalVisible(true);
  }

  const hideUpdateCategoriesModal = (e) => {
    setCategoriesModalClass("modal fade applicant-modal");
    setUpdateCategoriesModalVisible(false);
    setCategoriesModalClass("modal fade applicant-modal");
    setCategoriesModalVisible(false);
  }

  const addCategoriesModal = (e) => {
    setAddCategoriesModalVisible("modal fade applicant-modal show");
    setUpdateCategoriesModalVisible(false);
    setCategoriesModalVisible(false);
  }

  const showAddCategoriesModal = (e) => {
    setAddCategoriesModalVisible("modal fade applicant-modal show");
    setUpdateCategoriesModalVisible(false);
    setAddCategoriesModalVisible(true);
  }

  const hideAddCategoriesModal = (e) => {
    setCategoriesModalClass("modal fade applicant-modal");
    setUpdateCategoriesModalVisible(false);
    setCategoriesModalClass("modal fade applicant-modal");
    setCategoriesModalVisible(false);
    setAddCategoriesModalVisible(false);
  }

  const retrieveCategories = async () => {
    const response = fetch('https://app.4pointtkd-members.com/api/category', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveCategory = async (e) => {
    const response = fetch('https://app.4pointtkd-members.com/api/category/' + e, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const deleteCategory = async (e) => {
    const response = await fetch('https://app.4pointtkd-members.com/api/category/' + e, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    });
    // return response;

    // console.log(response);
    const json = await response.json();

    if (json.status == "Success") {
      //history.push("/thank-you-for-signing-up");
      // alert("Category deleted successfully");
      toast.success('Category deleted successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setReloadCount(reloadCount + 1);
      // window.location.reload();
    } else {
      alert("Deleting category failed!");
      toast.error('Deleting category failed!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setReloadCount(reloadCount + 1);
    }
  };

  useEffect(() => {
    const getCategories = async () => {
      const selectedCategories = await retrieveCategories();
      if (selectedCategories) {
        console.log("Selected Categories", selectedCategories);
        setCategoriesReady(true);
        setCategories(selectedCategories);
        setCategoriesCount(selectedCategories.length);
        // setFormData({
        //   ...formData,
        //   id: selectedCategories.id,
        //   name: selectedCategories.name,
        //   created_at: selectedCategories.created_at,
        // });
        // console.log("Form Data", formData);
      } else {
        setCategoriesReady(true);
      }
    };
    getCategories();
    // console.log("categories", categories);
  }, [reloadCount]);

  const getCategoryData = (e) => {
    const getCategory = async (e) => {
      const selectedCategory = await retrieveCategory(e);
      // console.log(selectedCategory)
      setFormData({
        ...formData,
        id: selectedCategory.id,
        name: selectedCategory.name,
        applications: selectedCategory.applications,
      });
      // console.log("length of gallery:", selectedGallery.length);
    }
    getCategory(e);
  }

  const showModal = (e) => {
    setModalClass("modal fade show");
    const applicantId = e.target.value;
  }

  const hideModal = (e) => {
    setModalClass("modal fade");
  }

  const logOut = (e) => {
    sessionStorage.clear();
    history.push("/login");
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    //console.log(formData);
  };

  const handleChangeAdd = (e) => {
    setAddFormData({
      ...formAddData,
      [e.target.name]: e.target.value,
    });
    //console.log(formData);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  }

  async function formSubmit(e) {
    //console.log(formData);
    e.preventDefault();
    if (formData.name != "") {
      setFormStatus("loading");
      const response = await fetch("https://app.4pointtkd-members.com/api/category/" + formData.id, {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrer: "client",
        body: JSON.stringify(formData),
      });

      const json = await response.json();

      if (json.status == "Success") {
        //history.push("/thank-you-for-signing-up");
        // alert("Category updated successfully");
        toast.success('Category updated successfully!', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // window.location.reload();
        setFormStatus("standby");
        setReloadCount(reloadCount + 1);
        setUpdateCategoriesModalVisible(false);
      } else {
        alert("Category update failed!");
        setFormStatus("standby");

      }
    } else {
      // alert("Please fill up category name!");
      setFormStatus("standby");
      toast.error('Please fill up category name!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function formAddSubmit(e) {
    //console.log(formData);
    e.preventDefault();
    if (formAddData.name != "") {
      setFormStatus("loading");
      const response = await fetch("https://app.4pointtkd-members.com/api/category", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrer: "client",
        body: JSON.stringify(formAddData),
      });

      const json = await response.json();

      if (json.status == "Success") {
        //history.push("/thank-you-for-signing-up");
        // alert("Category added successfully");
        setFormStatus("standby");
        toast.success('Category added successfully!', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // window.location.reload();
        
        setReloadCount(reloadCount + 1);
        setAddCategoriesModalVisible(false);
      } else {
        // alert("Adding category failed!");
        setFormStatus("standby");
        toast.error('Adding category failed!', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
       
      }
    } else {
      // alert("Please fill up category name!");
      toast.error('Please fill up category name!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus("standby");
    }
  }

  return (
    <>
      {updateCategoriesModalVisible ?
        <tr>
          <td>
            <div className="modal fade applicant-modal show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Category Details</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideUpdateCategoriesModal} >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <h5 className="applicant-modal-subheader">
                          Category Information
                        </h5>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label>Name</label>
                        <input type="text" className="form-control" name="name" onChange={handleChange} value={formData.name} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideUpdateCategoriesModal} >Close</button>
                    {formStatus != "standby" ?
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={preventDefault}>Saving...</button>
                      :
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={formSubmit}>Save</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        :
        null
      }

      {addCategoriesModalVisible ?
        <tr>
          <td>
            <div className="modal fade applicant-modal show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">New Category Details</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideAddCategoriesModal} >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <h5 className="applicant-modal-subheader">
                          New Category
                        </h5>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label>Name</label>
                        <input type="text" className="form-control" name="name" onChange={handleChangeAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideAddCategoriesModal} >Close</button>
                    {formStatus != "standby" ?
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={preventDefault}>Adding...</button>
                      :
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={formAddSubmit}>Add</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        :
        null
      }

      {loggedIn === "true" ?
        <Container>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-xs-12">
              <div className="right-sideabr card">
                <center>
                  <img className="user-image" src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" alt="Profile Image" />
                  <h4>
                    {userName}
                    <br />
                  </h4>
                </center>
                <ul className="list-item">
                  <li>
                    <a href="/applications">
                      Applications
                    </a>
                  </li>
                  <li>
                    <a href="/categories">
                      Categories
                    </a>
                  </li>
                  <li>
                    <a href="/classes">
                      Classes
                    </a>
                  </li>
                  <li>
                    <a href="/category-changes">
                      Category Changes
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={logOut}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="mb-3">Categories</h2>
                </div>
                <div className="col-lg-6 text-right">
                  <button type="button" className="record-action new-category-btn" data-dismiss="modal" onClick={function () { showAddCategoriesModal(); }}><FaPlus className="plus-icon"/> New Category</button>
                </div>
              </div>
              <table className="table table-bordered table-hover bg-white">
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th className="applications-head">Applications</th>
                  </tr>
                </thead>
                <tbody>
                  {categoriesReady ?
                    <>
                      {applicantsCategoriesCount > 0 ?
                        categories.map((category, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{category.name}</td>
                                <td>{category.applications ? category.applications : "0"}</td>
                                <td>
                                  {/* <button className="record-action category-btn" onClick={showUpdateApplicantModal}>
                              Edit
                            </button> */}
                                  <button className="category-btn" data-dismiss="modal" onClick={function () { showUpdateCategoriesModal(); getCategoryData(category.id) }} value={category.id}><BsFillPencilFill /></button>
                                  <button className="category-btn" onClick={function () { deleteCategory(category.id) }}><FaTrash /></button>
                                </td>
                              </tr>
                            </>
                          );
                        })
                        :
                        <tr>
                          <td scope="row" colSpan="3" className="text-center">No records found</td>
                        </tr>
                      }
                    </>
                    :
                    <tr>
                      <td scope="row" colSpan="3" className="text-center">Loading...</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Container>
        :
        null
      }
    </>
  )

}

export default AllCategories