import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'

const CategoryLog = () => {
  let history = useHistory();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [applicantsCategoryLogsCount, setCategoryLogsCount] = useState(0);
  const [categoryLogs, setCategoryLogs] = useState([]);
  const loggedIn = sessionStorage.getItem("loggedIn");
  const userName = userData.user.name;
  const [categoryLogsReady, setCategoryLogsReady] = useState(false);

  const retrieveCategoryLogs = async () => {
    const response = fetch('https://app.4pointtkd-members.com/api/log', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  useEffect(() => {
    const getCategoryLogs = async () => {
      const selectedCategoryLogs = await retrieveCategoryLogs();
      if (selectedCategoryLogs) {
        setCategoryLogsReady(true);
        setCategoryLogs(selectedCategoryLogs);
        setCategoryLogsCount(selectedCategoryLogs.length);
      } else {
        setCategoryLogsReady(true);
      }
    };
    getCategoryLogs();
  }, []);

  const logOut = (e) => {
    sessionStorage.clear();
    history.push("/login");
  }

  return (
    <>
      {loggedIn === "true" ?
        <Container>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-xs-12">
              <div className="right-sideabr card">
                <center>
                  <img className="user-image" src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" alt="Profile Image" />
                  <h4>
                    {userName}
                    <br />
                  </h4>
                </center>
                <ul className="list-item">
                  <li>
                    <a href="/applications">
                      Applications
                    </a>
                  </li>
                  <li>
                    <a href="/categories">
                      Categories
                    </a>
                  </li>
                  <li>
                    <a href="/classes">
                      Classes
                    </a>
                  </li>
                  <li>
                    <a href="/category-changes">
                      Category Changes
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={logOut}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <h2 className="mb-3">Category Changes</h2>
              <table className="table table-bordered table-hover bg-white">
                <thead className="thead-light">
                  {/* <tr>
                    <th scope="col" colSpan={2}>Category Logs</th>
                  </tr> */}
                </thead>
                <tbody>
                  {categoryLogsReady ?
                    <>
                      {applicantsCategoryLogsCount > 0 ?
                        categoryLogs.map((categoryLog, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  {categoryLog.message}
                                </td>
                                <td className="text-right">
                                  {categoryLog.created_at}
                                </td>
                              </tr>
                            </>
                          );
                        })
                        :
                        <tr>
                          <td scope="row" colSpan="6" className="text-center">No records found</td>
                        </tr>
                      }
                    </>
                    :
                    <tr>
                      <td scope="row" colspan="7" className="text-center">Loading...</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Container>
        :
        null
      }
    </>
  )
}

export default CategoryLog