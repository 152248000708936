import { Container } from 'react-bootstrap'
import { Route, Switch } from 'react-router-dom'
import './App.css'

// Layout
import Layout from './layout/Layout'

// pages
import Home from './pages/Home'
import About from './pages/About'
import Register from './pages/Register'
import Login from './pages/Login'
import Applications from './pages/Applications'
import NotFound from './pages/NotFound'
import ThankYouForSigningUp from './pages/ThankYouForSigningUp'
import Categories from './pages/Categories'
import CategoryChanges from './pages/CategoryChanges'
import Application from './pages/Application'
import ClassesPage from './pages/ClassesPage'
import ViewClassPage from './pages/ViewClassPage'

const App = () => {
  return (
    <>
        <Layout>
            <Container>
                <Switch>
                  <Route path='/' component={Home} exact />
                  <Route path='/about' component={About} />
                  <Route path='/register' component={Register} />
                  <Route path='/login' component={Login} />
                  <Route path='/applications' component={Applications} />
                  <Route path='/thank-you-for-signing-up' component={ThankYouForSigningUp} />
                  <Route path='/categories' component={Categories} />
                  <Route path='/category-changes' component={CategoryChanges} />
                  <Route path='/application' component={Application} />
                  <Route path='/classes' component={ClassesPage} />
                  <Route path='/view-class' component={ViewClassPage} />
                  <Route component={NotFound} />
                </Switch>
          </Container>
        </Layout>
    </>
  )
}

export default App
