import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from 'react-to-print';
import ReactToPrint from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BsEyeFill} from 'react-icons/bs'

const ApplicantRow = (props) => {
    const [applicantModalClass, setApplicantModalClass] = useState("modal fade");
    const [mediaReleaseModalClass, setMediaReleaseModalClass] = useState("modal fade");
    const [waiverModalClass, setWaiverModalClass] = useState("modal fade");
    const [showApplicantPrintable, setShowApplicantPrintable] = useState(false);
    const [showMediaReleasePrintable, setShowMediaReleasePrintable] = useState(false);
    const [showWaiverPrintable, setShowWaiverPrintable] = useState(false);
    const [applicantModalVisible, setApplicantModalVisible] = useState(false);
    const [mediaReleaseModalVisible, setMediaReleaseModalVisible] = useState(false);
    const [waiverModalVisible, setWaiverModalVisible] = useState(false);
    const [updateApplicantModalVisible, setUpdateApplicantModalVisible] = useState(false);
    const [deleteApplicationModalVisible, setDeleteApplicationModalVisible] = useState(false);
    const { id, first_name, last_name, birth_date, gender, phone_number, notes, email_address, address, city, postal_code, notifications, guardian_first_name, guardian_last_name, guardian_phone_number, guardian_relationship, pregnant_flag, illness_flag, illness_description, medications_flag, medications_description, injuries_flag, injuries_description, concussion_flag, concussion_description, others_flag, others_description, goal, reference, signature, media_release_signature, signee, media_release, category_id, category, code } = props.applicant;
 
    toast.configure();
    
    const showApplicantModal = (e) => {
        setApplicantModalClass("modal fade applicant-modal show");
        setShowApplicantPrintable(true);
        setApplicantModalVisible(true);
    }

    const hideApplicantModal = (e) => {
        setApplicantModalClass("modal fade applicant-modal");
        setShowApplicantPrintable(false);
        setApplicantModalVisible(false);
    }

    const showUpdateApplicantModal = (e) => {
        setApplicantModalClass("modal fade applicant-modal show");
        setUpdateApplicantModalVisible(true);
    }

    const hideUpdateApplicantModal = (e) => {
        setApplicantModalClass("modal fade applicant-modal");
        setUpdateApplicantModalVisible(false);
        setApplicantModalClass("modal fade applicant-modal");
        setShowApplicantPrintable(false);
        setApplicantModalVisible(false);
    }

    const showMediaReleaseModal = (e) => {
        setMediaReleaseModalClass("modal fade applicant-modal show");
        setMediaReleaseModalVisible(true);
        setShowMediaReleasePrintable(true);
        setApplicantModalClass("modal fade applicant-modal");
        setShowApplicantPrintable(false);
        setApplicantModalVisible(false);
    }

    const showDeleteConfirmationModal = (e) => {
        setDeleteApplicationModalVisible(true);
        setApplicantModalClass("modal fade applicant-modal");
        setApplicantModalVisible(false);
    }

    const hideDeleteConfirmationModal = (e) => {
        setDeleteApplicationModalVisible(false);
    }

    const hideMediaReleaseModal = (e) => {
        setMediaReleaseModalClass("modal fade applicant-modal");
        setMediaReleaseModalVisible(false);
        setShowMediaReleasePrintable(false);
        setApplicantModalClass("modal fade applicant-modal");
        setShowApplicantPrintable(false);
        setApplicantModalVisible(false);
    }

    const showWaiverModal = (e) => {
        setWaiverModalClass("modal fade applicant-modal show");
        setWaiverModalVisible(true);
        setShowWaiverPrintable(true);

        setMediaReleaseModalClass("modal fade applicant-modal");
        setMediaReleaseModalVisible(false);
        setShowMediaReleasePrintable(false);
        setApplicantModalClass("modal fade applicant-modal");
        setShowApplicantPrintable(false);
        setApplicantModalVisible(false);
    }

    const hideWaiverModal = (e) => {
        setWaiverModalClass("modal fade applicant-modal");
        setWaiverModalVisible(false);
        setShowWaiverPrintable(false);
        setMediaReleaseModalClass("modal fade applicant-modal");
        setMediaReleaseModalVisible(false);
        setShowMediaReleasePrintable(false);
        setApplicantModalClass("modal fade applicant-modal");
        setShowApplicantPrintable(false);
        setApplicantModalVisible(false);
    }

    const [deleteData, setDeleteData] = useState({
        id: id
    });

    const [formData, setFormData] = useState({
        id: id,
        first_name: first_name,
        last_name: last_name,
        birth_date: birth_date,
        gender: gender,
        phone_number: phone_number,
        email_address: email_address,
        address: address,
        city: city,
        postal_code: postal_code,
        notifications: notifications,
        guardian_first_name: guardian_first_name,
        guardian_last_name: guardian_last_name,
        guardian_phone_number: guardian_phone_number,
        guardian_relationship: guardian_relationship,
        pregnant_flag: pregnant_flag,
        illness_flag: illness_flag,
        illness_description: illness_description,
        medications_flag: medications_flag,
        medications_description: medications_description,
        injuries_flag: injuries_flag,
        injuries_description: injuries_description,
        concussion_flag: concussion_flag,
        concussion_description: concussion_description,
        others_flag: others_flag,
        others_description: others_description,
        goal: goal,
        reference: reference,
        signature: signature,
        media_release_signature: media_release_signature,
        signee: signee,
        media_release: media_release,
        category_id: category_id,
        category: category,
        notes: notes,
        code: code
    });

    var birthdate = new Date(formData.birth_date).getFullYear();
    var currentDate = new Date().getFullYear();
    var applicantAge = currentDate - birthdate;

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        //console.log(formData);
    };

    const applicantRef = useRef();
    const mediaReleaseRef = useRef();
    const waiverRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => applicantRef.current,
    });

    const handlePrintMediaRelease = useReactToPrint({
        content: () => mediaReleaseRef.current,
    });

    const handlePrintWaiver = useReactToPrint({
        content: () => waiverRef.current,
    });

    async function formSubmit(e) {
        //console.log(formData);
        e.preventDefault();
        const response = await fetch("https://app.4pointtkd-members.com/api/application/" + formData.id, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrer: "client",
            body: JSON.stringify(formData),
        });

        const json = await response.json();

        if (json.status == "Success") {
            const formData = json.data.application
            setFormData({
                ...formData,
                id: id,
                first_name: formData.first_name,
                last_name: formData.last_name,
                birth_date: formData.birth_date,
                gender: formData.gender,
                phone_number: formData.phone_number,
                email_address: formData.email_address,
                address: formData.address,
                city: formData.city,
                postal_code: formData.postal_code,
                notifications: formData.notifications,
                guardian_first_name: formData.guardian_first_name,
                guardian_last_name: formData.guardian_last_name,
                guardian_phone_number: formData.guardian_phone_number,
                guardian_relationship: formData.guardian_relationship,
                pregnant_flag: formData.pregnant_flag,
                illness_flag: formData.illness_flag,
                illness_description: formData.illness_description,
                medications_flag: formData.medications_flag,
                medications_description: formData.medications_description,
                injuries_flag: formData.injuries_flag,
                injuries_description: formData.injuries_description,
                concussion_flag: formData.concussion_flag,
                concussion_description: formData.concussion_description,
                others_flag: formData.others_flag,
                others_description: formData.others_description,
                goal: formData.goal,
                reference: formData.reference,
                signature: formData.signature,
                media_release_signature: formData.media_release_signature,
                signee: formData.signee,
                media_release: formData.media_release,
                category_id: formData.category_id,
                notes: formData.notes
            });
            //history.push("/thank-you-for-signing-up");
            // alert("Application updated successfully");
            toast.success('Application updated successfully', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            window.location.reload();
        }
    }

    async function deleteApplication(e) {
        const response = await fetch("https://app.4pointtkd-members.com/api/application/" + formData.id, {
            method: "DELETE",
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'client', // no-referrer, *client
        });

        //const json = await response.json();
        //console.log(response.status);
        if (response.status == 200) {
            // alert("Application deleted successfully");
            toast.success('Application deleted successfully', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            window.location.reload();
        }
    }

    class ApplicationPrint extends React.PureComponent {
        render() {
            return (
                <div className="modal-dialog" role="document">
                    <div className="modal-content no-border">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Applicant Details</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row page">
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Applicant Information
                                    </h5>
                                </div>
                                <div className="col-lg-6">
                                    <label>First Name</label>
                                    <p className="applicant-modal-text">{formData.first_name}</p>
                                </div>
                                <div className="col-lg-6">
                                    <label>Last Name</label>
                                    <p className="applicant-modal-text">{formData.last_name}</p>
                                </div>
                                <div className="col-lg-12 mb-3"></div>
                                <div className="col-lg-6">
                                    <label>Birth Date</label>
                                    <p className="applicant-modal-text">{formData.birth_date}</p>
                                </div>
                                <div className="col-lg-6">
                                    <label>Gender</label>
                                    <p className="applicant-modal-text">{formData.gender}</p>
                                </div>
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Contact Information
                                    </h5>
                                </div>
                                <div className="col-lg-6">
                                    <label>Phone Number</label>
                                    <p className="applicant-modal-text">{formData.phone_number}</p>
                                </div>
                                <div className="col-lg-6">
                                    <label>Email Address</label>
                                    <p className="applicant-modal-text">{formData.email_address}</p>
                                </div>
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Address Information
                                    </h5>
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <label>Address</label>
                                    <p className="applicant-modal-text">{formData.address}</p>
                                </div>
                                <div className="col-lg-6">
                                    <label>City</label>
                                    <p className="applicant-modal-text">{formData.city}</p>
                                </div>
                                <div className="col-lg-6">
                                    <label>Postal Code</label>
                                    <p className="applicant-modal-text">{formData.postal_code}</p>
                                </div>
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Notifications
                                    </h5>
                                </div>
                                <div className="col-lg-12">
                                    <label>Receive notifications via email regarding special events and promotions?</label>
                                    {formData.notifications == "0" ?
                                        <p className="applicant-modal-text">No</p>
                                        :
                                        <p className="applicant-modal-text">Yes</p>
                                    }

                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Parent / Guardian Emergency Contact
                                    </h5>
                                </div>
                                <div className="col-lg-6">
                                    <label>First Name</label>
                                    <p className="applicant-modal-text">{formData.guardian_first_name}</p>
                                </div>
                                <div className="col-lg-6">
                                    <label>Last Name</label>
                                    <p className="applicant-modal-text">{formData.guardian_last_name}</p>
                                </div>
                                <div className="col-lg-12 mb-3"></div>
                                <div className="col-lg-6">
                                    <label>Phone Number </label>
                                    <p className="applicant-modal-text">{formData.guardian_phone_number}</p>
                                </div>
                                <div className="col-lg-6">
                                    <label>Relationship</label>
                                    <p className="applicant-modal-text">{formData.guardian_relationship}</p>
                                </div>
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                {formData.gender == "Female" ?
                                    <>
                                        <div className="col-lg-12">
                                            <h5 className="applicant-modal-subheader">
                                                Are you currently pregnant?
                                            </h5>
                                        </div>
                                        <div className="col-lg-6">
                                            {formData.pregnant_flag == "1" ?
                                                <p className="applicant-modal-text">Yes</p>
                                                :
                                                <p className="applicant-modal-text">No</p>
                                            }

                                        </div>
                                        <div className="col-lg-12">
                                            <hr />
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Do you suffer from any chronic or serious illness?
                                    </h5>
                                </div>
                                {formData.illness_flag == "1" ?
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">Yes. {formData.illness_description}</p>
                                    </div>
                                    :
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">No</p>
                                    </div>
                                }
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Are you currently taking any medications?
                                    </h5>
                                </div>
                                {formData.medications_flag == "1" ?
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">Yes. {formData.medications_description}</p>
                                    </div>
                                    :
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">No</p>
                                    </div>
                                }
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Are you experiencing any chronic pain/ injuries?
                                    </h5>
                                </div>
                                {formData.injuries_flag == "1" ?
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">Yes. {formData.injuries_description}</p>
                                    </div>
                                    :
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">No</p>
                                    </div>
                                }
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Have you ever suffered a concussion?
                                    </h5>
                                </div>
                                {formData.concussion_flag == "1" ?
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">Yes. {formData.concussion_description}</p>
                                    </div>
                                    :
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">No</p>
                                    </div>
                                }
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Do you have any other medical conditions(s) we should be aware of?
                                    </h5>
                                </div>
                                {formData.others_flag == "1" ?
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">Yes. {formData.others_description}</p>
                                    </div>
                                    :
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">No</p>
                                    </div>
                                }
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        What are you looking to get out of martial arts?
                                    </h5>
                                </div>
                                <div className="col-lg-12">
                                    <p className="applicant-modal-text">{formData.goal}</p>
                                </div>
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        How did you hear about us?
                                    </h5>
                                </div>
                                <div className="col-lg-12">
                                    <p className="applicant-modal-text">{formData.reference}</p>
                                </div>
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Signee
                                    </h5>
                                </div>
                                {applicantAge > 17 ?
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">{formData.first_name} {formData.last_name}</p>
                                    </div>
                                    :
                                    <div className="col-lg-12">
                                        <p className="applicant-modal-text">{formData.guardian_first_name} {formData.guardian_last_name}</p>
                                    </div>
                                }
                                {formData.media_release_signature != "" ?
                                    <>
                                        <div className="col-lg-12">
                                            <hr />
                                        </div>
                                        <div className="col-lg-12">
                                            <h5 className="applicant-modal-subheader">
                                                Signed Media Release
                                            </h5>
                                            <p className="applicant-modal-text">Yes</p>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div className="col-lg-12">
                                            <hr />
                                        </div>
                                        <div className="col-lg-12">
                                            <h5 className="applicant-modal-subheader">
                                                Signed Media Release
                                            </h5>
                                            <p className="applicant-modal-text">No</p>
                                        </div>

                                    </>
                                }
                                <div className="col-lg-12">
                                    <hr />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="applicant-modal-subheader">
                                        Notes:
                                    </h5>
                                </div>
                                <div className="col-lg-12">
                                    <p className="applicant-modal-text">{formData.notes}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    class MediaReleaseSignaturePrint extends React.PureComponent {
        render() {
            return (
                <div className="modal-dialog" role="document">
                    <div className="modal-content no-border">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Media Release</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="page">
                                                    <p><strong>PHOTO AND VIDEO CONSENT, ASSIGNMENT AND RELEASE FORM</strong></p>
                                                    <p>PURPOSES: For marketing, advertising, promotional and/or communication purposes, 4Point Taekwondo, from time to time, take photographs and/or video recordings of 4Point Taekwondo based activities or events that include real people, which photographs and video recordings will be placed in the 4Point Taekwondo Photo Bank and which may be used by 4Point Taekwondo, for its own informational, promotional or advertising purposes. For purposes of this Form, “4Point Taekwondo” refers to 4Point Taekwondo Inc. in Canada.</p>
                                                    <p>By signing this Form, you are consenting to the taking of photographs and/or video recordings of you by 4Point Taekwondo for the Purposes, you are assigning to 4Point Taekwondo, and waiving any rights you have related to, any such photographs and/or video recordings, and you are consenting to the use of any such photographs and/or video recordings, in whole or in part, by 4Point Taekwondo.</p>
                                                    <p>For valuable consideration received but without any promise of remuneration, I hereby agree to allow photographs and/or video recordings to be taken of me, whether posed or candid, while I am on 4Point Taekwondo property and/or participating in 4Point Taekwondo activities or events, to be reproduced, published, displayed, broadcast, transmitted, licensed, sublicensed or otherwise used by 4Point Taekwondo  in connection with the Purposes, including without limitation on 4Point Taekwondo internet web sites, in 4Point Taekwondo printed materials, or in any other materials or medium whatsoever and wherever (the “Work Product”). I confirm that neither 4Point Taekwondo shall be obligated to use the Work Product.</p>
                                                    <p>I understand that the Work Product is being created under the direction and control of the 4Point Taekwondo. I hereby irrevocably assign to 4Point Taekwondo any and all </p>
                                                </div>
                                                <div className="page">
                                                    <p>rights, including copyright, financial or other rights, and I hereby irrevocably waive in favour 4Point Taekwondo and any Authorized Third Party any and all moral rights or rights of similar nature that I may have in the Work Product. I agree that 4Point Taekwondo has the sole worldwide ownership and rights in and to the Work Product, including copyright interests, and I acknowledge that I have no interest or ownership in the Work Product or its copyright.</p>
                                                    <p>I understand that the Work Product is being created under the direction and control of 4Point Taekwondo. I hereby irrevocably assign to 4Point Taekwondo any and all rights, including copyright, financial or other rights, and I hereby irrevocably waive in favour of 4Point Taekwondo any and all moral rights or rights of similar nature that I may have in the Work Product. I agree that 4Point Taekwondo has the sole worldwide ownership and rights in and to the Work Product, including copyright interests, and I acknowledge that I have no interest or ownership in the Work Product or its copyright.</p>
                                                    <p>I confirm that I am over the age of majority in my province or territory of residence and am competent to execute this Form and to participate in the development of the Work Product; or, to the extent that I am under the age of majority in my province or territory of residence, have had my parent or guardian review this Form and consent to my participation in the creation of the Work Product on my behalf.</p><p>By signing my name, I (and my legal guardian, where applicable) acknowledge that I (or we) have carefully read and understand this Form.</p>
                                                    <p><strong>ACKNOWLEDGEMENT OF RISK AND RELEASE OF LIABILITY</strong></p>
                                                    <p>The following waiver of all claims, release from all liability, assumption of all risks, agreement not to sue and other terms of this agreement are entered into by me on behalf of the undersigned Student (the "Student") with and for the benefit of 4Point</p><p> Taekwondo Inc., its directors, officers, employees, volunteers, business operators, agents and site property owners or lessees (the "Organization").</p>
                                                    <p>1. I am over the Age of Majority and am executing this waiver on behalf of myself or the Student in my capacity as Parent/Guardian&nbsp;with the intent that this waiver be binding on myself and the Student for all purposes.</p>
                                                    <p>2. I am aware that there are inherent and significant dangers, hazards and risks (“Risks') associated with the participation in Martial Arts Activities. I am aware that the Risks include but are not limited to injury from physical contact with other students, instructors or equipment performing a skill incorrectly, or potentially dangerous obstacles, conditions or weapons on the floor or vicinity of the Martial Arts Activities. I understand that the Risks are relative to my state of fitness or health (physical, mental and emotional), and to the awareness, care and skill with which the student conducts him or herself while participating in Martial Arts Activities.</p>
                                                    <p>3. I freely accept and fully assume all responsibility for all Risks and possibilities of personal injury, death, property damage or loss resulting from my participation in Martial Arts Activities. I freely assume responsibility for my own safety. I agree that although the Organization has taken steps to reduce the Risks and increase the safety of the Martial Arts Activities, it is not possible for the Organization to make the Martial Arts Activities completely safe. I accept these Risks and agree to the terms of this waiver even if the Organization is found to be negligent or in breach of any duty of care or any obligation to me in my participation in Martial Arts Activities.</p>
                                                    <p>4. I acknowledge my obligation to inform the nearest employee of the Organization if I feel any pain, discomfort, fatigue or any other symptoms I may suffer during or immediately after my participation in Martial Arts Activities. I understand that I may stop participating at any time, and have the right to immediately withdraw from any exercise or drill in which the conduct of any party seems beyond the scope of training, makes me uncomfortable or which I believe will be harmful to me.</p>
                                                    <p>5. I confirm that I have reached the age of majority in the Province or Territory in which I am participating in Martial Arts Activities.</p>
                                                    <p>6. In addition to consideration given to the Organization for my participation in Martial Arts Activities, I and my heirs, next of kin, executors, administrators and assigns (my "Legal Representatives") agree:</p>
                                                    <p>(a) to waive all claims that I or my Legal Representatives have or may have in the future</p>
                                                    <p>against the Organization; and</p>
                                                    <p>(b) to release and forever discharge the Organization from all liability for all personal injury, death, property damage or loss resulting from my participation in Martial Arts Activities due to any cause, including but not limited to negligence (failure to use such care as a reasonably prudent and careful person would use under similar circumstances), breach of any duty imposed by law, breach of contract or mistake or error in judgment of the Organization.</p>
                                                    <p>7. I agree to be liable for and to hold harmless and indemnify the Organization from all actions, proceedings, claims, damages, costs demands including court costs and costs on a solicitor and own client basis, and liabilities of whatsoever nature or kind arising out of or in any way connected with my participation in Martial Arts Activities.</p>
                                                    <p>8. I agree that this waiver and all terms contained within are governed by the laws of the Province or Territory in which I am participating in Martial Arts Activities. I hereby</p>
                                                </div>
                                                <div class="page">
                                                    <p>irrevocably submit to the jurisdiction of the courts of that Province or Territory.</p>
                                                    <p>9. I confirm that I have had sufficient time to read and understand each term in this waiver in its entirety, and have agreed to the terms freely and voluntarily. I understand that this waiver is binding on myself and my Legal Representatives.</p>
                                                    {formData.media_release_signature != "" ?
                                                        <>
                                                            <p className="applicant-modal-subheader">
                                                                Signed: {formData.first_name} {formData.last_name}
                                                            </p>
                                                            <img src={formData.media_release_signature} style={{ maxWidth: "250px" }} className="applicant-modal-image" />
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    class WaiverSignaturePrint extends React.PureComponent {
        render() {
            return (
                <div className="modal-dialog" role="document">
                    <div className="modal-content no-border">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Waiver</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <tbody>
                                        <tr>
                                            <td>
                                                {applicantAge > 17 ?
                                                    <>
                                                        <div className="page">
                                                            <h5>Acknowledgement of Risk and Release of Liability (18+)</h5>
                                                            <p>For Students over the Age of Majority in the Province or Territory in which the Martial Arts Activities are provided by the Organization</p>
                                                            <br />
                                                            <h5>WARNING: THIS AGREEMENT WILL AFFECT YOUR LEGAL RIGHTS. READ IT CAREFULLY!</h5>
                                                            <br />
                                                            <p>Every Student Must Read and Understand this Waiver before Participating in Martial Arts Activities</p>
                                                            <br />
                                                            <p>The following waiver of all claims, release from all liability, assumption of all risks, agreement not to sue and other terms of this agreement are entered into by me on behalf of the undersigned Student</p>
                                                            <p>(the "Student") with and for the benefit of 4Point Taekwondo Inc., its directors, officers, employees, volunteers, business operators, agents and site property owners or lessees (the "Organization").</p>
                                                            <p>1. "Martial Arts Activities" includes but is not limited to contact and non-contact martial arts activities, lessons, classes, training, use of facilities, programs and services provided to the Student by the Organization.</p>
                                                            <p>2. I am aware that there are inherent and significant dangers, hazards and risks (“Risks') associated with the participation in Martial Arts Activities. I am aware that the Risks include but are not limited to injury from physical contact with other students, instructors or equipment performing a skill incorrectly, or potentially dangerous obstacles, conditions or weapons on the floor or vicinity of the Martial Arts Activities. I understand that the Risks are relative to my state of fitness or health (physical, mental and emotional), and to the awareness, care and skill with which the student conducts him or herself while participating in Martial Arts Activities.</p>
                                                            <p>3. I freely accept and fully assume all responsibility for all Risks and possibilities of personal injury, death, property damage or loss resulting from my participation in Martial Arts Activities. I freely assume responsibility for my own safety. I agree that although the Organization has taken steps to reduce the Risks and increase the safety of the Martial Arts Activities, it is not possible for the Organization to make the Martial Arts Activities completely safe. I accept these Risks and agree to the terms of this waiver even if the Organization is found to be negligent or in breach of any duty of care or any obligation to me in my participation in Martial Arts Activities.</p>
                                                            <p>4. I acknowledge my obligation to inform the nearest employee of the Organization if I feel any pain, discomfort, fatigue or any other symptoms I may suffer during or immediately after my participation in Martial Arts Activities. I understand that I may stop participating at any time, and have the right to immediately withdraw from any exercise or drill in which the conduct of any party seems beyond the scope of training, makes me uncomfortable or which I believe will be harmful to me.</p>
                                                            <p>5. I confirm that I have reached the age of majority in the Province or Territory in which I am participating in Martial Arts Activities.</p>
                                                            <p>6. In addition to consideration given to the Organization for my participation in Martial Arts Activities, I and my heirs, next of kin, executors, administrators and assigns (my "Legal Representatives") agree:</p><p> </p><p>(a) to waive all claims that I or my Legal Representatives have or may have in the future</p><p>against the Organization; and</p><p> </p><p>(b) to release and forever discharge the Organization from all liability for all personal injury,</p><p>death, property damage or loss resulting from my participation in Martial Arts Activities due to any cause, including but not limited to negligence (failure to use such care as a reasonably prudent and careful person would use under similar circumstances), breach of any duty imposed by law, breach of contract or mistake or error in judgment of the Organization.</p>
                                                            <p>7. I agree to be liable for and to hold harmless and indemnify the Organization from all actions, proceedings, claims, damages, costs demands including court costs and costs on a solicitor and own client basis, and liabilities of whatsoever nature or kind arising out of or in any way connected with my participation in Martial Arts Activities.</p>
                                                            <p>8. I agree that this waiver and all terms contained within are governed by the laws of the Province or Territory in which I am participating in Martial Arts Activities. I hereby irrevocably submit to the jurisdiction of the courts of that Province or Territory.</p>
                                                            <p>9. I confirm that I have had sufficient time to read and understand each term in this waiver in its entirety, and have agreed to the terms freely and voluntarily. I understand that this waiver is binding on myself and my Legal Representatives.</p>
                                                            {applicantAge > 17 ?
                                                                <>
                                                                    <p className="applicant-modal-text">Signed: {formData.first_name} {formData.last_name}</p>
                                                                    <img src={formData.signature} style={{ maxWidth: "250px" }} className="applicant-modal-image" />
                                                                </>
                                                                :
                                                                <>
                                                                    <p className="applicant-modal-text">Signed: {formData.guardian_first_name} {formData.guardian_last_name}</p>
                                                                    <img src={formData.signature} style={{ maxWidth: "250px" }} className="applicant-modal-image" />
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="page">
                                                            <h5>Acknowledgement of Risk and Release of Liability (Under 18)</h5>
                                                            <p>For Students under the Age of Majority in the Province or Territory in which the Martial Arts Activities are provided by the Organization</p>
                                                            <br />
                                                            <h5>WARNING: THIS AGREEMENT WILL AFFECT YOUR LEGAL RIGHTS. READ IT CAREFULLY!</h5>
                                                            <br />
                                                            <p>Every Parent/Guardian Must Read and Understand this Waiver Prior to the Student Participating in Martial Arts Activities</p>
                                                            <br />
                                                            <p>The following waiver of all claims, release from all liability, assumption of all risks, agreement not to sue and other terms of this agreement are entered into by me on behalf of the undersigned Student (the "Student") with and for the benefit of 4Point Taekwondo Inc., its directors, officers, employees, volunteers, business operators, agents and site property owners or lessees (the "Organization").</p>
                                                            <p>1. I am the Parent/Guardian of the Student and am executing this waiver on behalf of the Student in my capacity as Parent/Guardian and with the intent that this waiver be binding on myself and the Student for all purposes.</p>
                                                            <p>2. "Martial Arts Activities" includes but is not limited to contact and non-contact martial arts activities, lessons, classes, training, use of facilities, programs and services provided to the Student by the Organization.</p>
                                                            <p>3. I am aware that there are inherent and significant dangers, hazards and risks (“Risks") associated with the participation in Martial Arts Activities. I am aware that the Risks include but are not limited to injury from physical contact with other students, instructors or equipment, performing a skill incorrectly, or potentially dangerous obstacles, conditions or weapons on the floor or vicinity of the Martial Arts Activities. I understand that the Risks are relative to the Student's state of fitness or health (physical, mental and emotional), and to the awareness, care and skill with which the student conducts him or herself while participating in Martial Arts Activities.</p>
                                                            <p>4. I freely accept and fully assume all responsibility for all Risks and possibilities of personal injury, death, property damage or loss resulting from the Student's participation in Martial Arts Activities. I agree that although the Organization has taken steps to reduce the Risks and increase the safety of the Martial Arts Activities, it is not possible for the Organization to make the Martial Arts Activities completely safe. I accept these Risks and agree to the terms of this waiver even if the Organization is found to be negligent or in breach of any duty of care or any obligation to the Student in the Student's participation in Martial Arts Activities.</p>
                                                            <p>5. I acknowledge the Student's obligation to inform the nearest employee of the Organization if the Student feels any pain, discomfort, fatigue or any other symptoms the Student may suffer during or immediately after his or her participation in Martial Arts Activities. I understand that the Student may stop participating at any time, and has the right to immediately withdraw from any exercise or drill in which the conduct of any party seems beyond the scope of training, makes the Student uncomfortable or which the Student believes will be harmful to him or her.</p>
                                                            <p>6. In addition to consideration given to the Organization for the Student's participation in  Martial Arts Activities, I and my heirs, next of kin, executors, administrators and assigns, as well as the Student's heirs, next of kin, executors, administrators and assigns (collectively our “Legal Representatives") agree:</p><p> </p><p>(a) to waive all claims that I, the Student or our Legal Representatives have or may have in the</p><p>future against the Organization; and</p><p> </p><p>(b) to release and forever discharge the Organization from all liability for all personal injury, death, property damage or loss resulting from the Student's participation in Martial Arts Activities due to any cause, including but not limited to negligence (failure to use such care as a reasonably prudent and careful person would use under similar circumstances), breach of any duty imposed by law, breach of contract or mistake or error in judgment of the Organization.</p>
                                                            <p>7. I agree to be liable for and to hold harmless and indemnify the Organization from all actions, proceedings, claims, damages, costs demands including court costs and costs on a solicitor and own client basis, and liabilities of whatsoever nature or kind arising out of or in any way connected with the Student's participation in Martial Arts Activities.</p>
                                                            <p>8. I agree that this waiver and all terms contained within are governed by the laws of the Province or Territory in which the Student is participating in Martial Arts Activities. I hereby irrevocably submit to the jurisdiction of the courts of that Province or Territory.</p>
                                                            <p>9. I confirm that I have had sufficient time to read and understand each term in this waiver in its entirety, and have agreed to the terms freely and voluntarily. I understand that this waiver is binding on myself, the Student and our Legal Representatives.</p>
                                                            {applicantAge > 17 ?
                                                                <>
                                                                    <p className="applicant-modal-text">Signed: {formData.first_name} {formData.last_name}</p>
                                                                    <img src={formData.signature} style={{ maxWidth: "250px" }} className="applicant-modal-image" />
                                                                </>
                                                                :
                                                                <>
                                                                    <p className="applicant-modal-text">Signed: {formData.guardian_first_name} {formData.guardian_last_name}</p>
                                                                    <img src={formData.signature} style={{ maxWidth: "250px" }} className="applicant-modal-image" />
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                }


                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <>
            {showApplicantPrintable ?
                <tr style={{ display: "none" }}>
                    <td>
                        <div style={{ display: "none" }}><ApplicationPrint ref={applicantRef} /></div>
                    </td>
                </tr>
                :
                null
            }
            {showMediaReleasePrintable ?
                <tr style={{ display: "none" }}>
                    <td>
                        <div style={{ display: "none" }}><MediaReleaseSignaturePrint ref={mediaReleaseRef} /></div>
                    </td>
                </tr>
                :
                null
            }
            {showWaiverPrintable ?
                <tr style={{ display: "none" }}>
                    <td>
                        <div style={{ display: "none" }}><WaiverSignaturePrint ref={waiverRef} /></div>
                    </td>
                </tr>
                :
                null
            }
            {deleteApplicationModalVisible ?
                <tr>
                    <td>
                        <div className="modal fade delete-application-modal show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Delete Application</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideDeleteConfirmationModal} >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Really delete this application?
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideDeleteConfirmationModal} >No</button>
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={deleteApplication} >Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                :
                null
            }
            {applicantModalVisible ?
                <tr>
                    <td>
                        <div className={applicantModalClass} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Applicant Details</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideApplicantModal} >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Applicant Information
                                                </h5>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>First Name</label>
                                                <p className="applicant-modal-text">{formData.first_name}</p>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>Last Name</label>
                                                <p className="applicant-modal-text">{formData.last_name}</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Birth Date</label>
                                                <p className="applicant-modal-text">{formData.birth_date}</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Gender</label>
                                                <p className="applicant-modal-text">{formData.gender}</p>
                                            </div>
                                            {formData.category !== "" && formData.category !== null ?
                                                <div className="col-lg-12 mt-2">
                                                    <label>Category</label>
                                                    <p className="applicant-modal-text">{formData.category}</p>
                                                </div>
                                                :
                                                null
                                            }

                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Contact Information
                                                </h5>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Phone Number</label>
                                                <p className="applicant-modal-text">{formData.phone_number}</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Email Address</label>
                                                <p className="applicant-modal-text">{formData.email_address}</p>
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Address Information
                                                </h5>
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label>Address</label>
                                                <p className="applicant-modal-text">{formData.address}</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>City</label>
                                                <p className="applicant-modal-text">{formData.city}</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Postal Code</label>
                                                <p className="applicant-modal-text">{formData.postal_code}</p>
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Notifications
                                                </h5>
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Receive notifications via email regarding special events and promotions?</label>
                                                {formData.notifications == "0" ?
                                                    <p className="applicant-modal-text">No</p>
                                                    :
                                                    <p className="applicant-modal-text">Yes</p>
                                                }
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Parent / Guardian Emergency Contact
                                                </h5>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>First Name</label>
                                                <p className="applicant-modal-text">{formData.guardian_first_name}</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Last Name</label>
                                                <p className="applicant-modal-text">{formData.guardian_last_name}</p>
                                            </div>
                                            <div className="col-lg-12 mb-3"></div>
                                            <div className="col-lg-6">
                                                <label>Phone Number </label>
                                                <p className="applicant-modal-text">{formData.guardian_phone_number}</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Relationship</label>
                                                <p className="applicant-modal-text">{formData.guardian_relationship}</p>
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            {formData.gender == "Female" ?
                                                <>
                                                    <div className="col-lg-12">
                                                        <h5 className="applicant-modal-subheader">
                                                            Are you currently pregnant?
                                                        </h5>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        {formData.pregnant_flag == "1" ?
                                                            <p className="applicant-modal-text">Yes</p>
                                                            :
                                                            <p className="applicant-modal-text">No</p>
                                                        }

                                                    </div>
                                                    <div className="col-lg-12">
                                                        <hr />
                                                    </div>
                                                </>
                                                :
                                                null
                                            }
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Do you suffer from any chronic or serious illness?
                                                </h5>
                                            </div>
                                            {formData.illness_flag == "1" ?
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">Yes. {formData.illness_description}</p>
                                                </div>
                                                :
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">No</p>
                                                </div>
                                            }
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Are you currently taking any medications?
                                                </h5>
                                            </div>
                                            {formData.medications_flag == "1" ?
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">Yes. {formData.medications_description}</p>
                                                </div>
                                                :
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">No</p>
                                                </div>
                                            }
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Are you experiencing any chronic pain/ injuries?
                                                </h5>
                                            </div>
                                            {formData.injuries_flag == "1" ?
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">Yes. {formData.injuries_description}</p>
                                                </div>
                                                :
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">No</p>
                                                </div>
                                            }
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Have you ever suffered a concussion?
                                                </h5>
                                            </div>
                                            {formData.concussion_flag == "1" ?
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">Yes. {formData.concussion_description}</p>
                                                </div>
                                                :
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">No</p>
                                                </div>
                                            }
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Do you have any other medical conditions(s) we should be aware of?
                                                </h5>
                                            </div>
                                            {formData.others_flag == "1" ?
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">Yes. {formData.others_description}</p>
                                                </div>
                                                :
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">No</p>
                                                </div>
                                            }
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    What are you looking to get out of martial arts?
                                                </h5>
                                            </div>
                                            <div className="col-lg-12">
                                                <p className="applicant-modal-text">{formData.goal}</p>
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    How did you hear about us?
                                                </h5>
                                            </div>
                                            <div className="col-lg-12">
                                                <p className="applicant-modal-text">{formData.reference}</p>
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Signee
                                                </h5>
                                            </div>
                                            {applicantAge > 17 ?
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">{formData.first_name} {formData.last_name}</p>
                                                </div>
                                                :
                                                <div className="col-lg-12">
                                                    <p className="applicant-modal-text">{formData.guardian_first_name} {formData.guardian_last_name}</p>
                                                </div>
                                            }

                                            {formData.media_release_signature != "" ?
                                                <>
                                                    <div className="col-lg-12">
                                                        <hr />
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <h5 className="applicant-modal-subheader">
                                                            Signed Media Release
                                                        </h5>
                                                    </div>
                                                    <p className="applicant-modal-text">Yes</p>
                                                </>
                                                :
                                                <>
                                                    <div className="col-lg-12">
                                                        <hr />
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <h5 className="applicant-modal-subheader">
                                                            Signed Media Release
                                                        </h5>
                                                    </div>
                                                    <p className="applicant-modal-text">No <a href={`/application?code=${formData.code}`} target="_blank">Sign Here</a></p>
                                                </>
                                            }
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Notes:
                                                </h5>
                                            </div>
                                            <div className="col-lg-12">
                                                <p className="applicant-modal-text">{formData.notes}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">

                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideApplicantModal} >Close</button>
                                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={showDeleteConfirmationModal} >Delete</button>
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={showUpdateApplicantModal}>Edit</button>
                                        <ReactToPrint
                                            trigger={() => <button className="btn btn-primary">Print</button>}
                                            content={() => applicantRef.current}
                                        />
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={showWaiverModal}>Waiver</button>
                                        {formData.media_release_signature != "" ?
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={showMediaReleaseModal} >Media Release</button>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                :
                null
            }
            {updateApplicantModalVisible ?
                <tr>
                    <td>
                        <div className="modal fade applicant-modal show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Applicant Details</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideUpdateApplicantModal} >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Applicant Information
                                                </h5>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>First Name</label>
                                                <input type="text" className="form-control" name="first_name" onChange={handleChange} value={formData.first_name} />
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" name="last_name" onChange={handleChange} value={formData.last_name} />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Birth Date</label>
                                                <input type="date" className="form-control" name="birth_date" onChange={handleChange} value={formData.birth_date} />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Gender</label>
                                                {formData.gender === "Male" ?
                                                    <select className="select-input" name="gender" onChange={handleChange} value={formData.gender}>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    :
                                                    <select className="select-input" name="gender" onChange={handleChange} value={formData.gender}>
                                                        <option value="Female">Female</option>
                                                        <option value="Male">Male</option>
                                                    </select>
                                                }
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Category</label>
                                                <select className="select-input" name="category_id" onChange={handleChange} value={formData.category_id}>
                                                    <option value="1">Free Trial</option>
                                                    <option value="2">1st of Month</option>
                                                    <option value="3">15th of Month</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Contact Information
                                                </h5>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Phone Number</label>
                                                <input type="tel" className="form-control" name="phone_number" onChange={handleChange} value={formData.phone_number} />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Email Address</label>
                                                <input type="email" className="form-control" name="email_address" onChange={handleChange} value={formData.email_address} />
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Address Information
                                                </h5>
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label>Address</label>
                                                <input type="text" className="form-control" name="address" onChange={handleChange} value={formData.address} />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>City</label>
                                                <input type="text" className="form-control" name="city" onChange={handleChange} value={formData.city} />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Postal Code</label>
                                                <input type="text" className="form-control" name="postal_code" onChange={handleChange} value={formData.postal_code} />
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Notifications
                                                </h5>
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Receive notifications via email regarding special events and promotions?</label>
                                                {formData.notifications == "0" ?
                                                    <p className="applicant-modal-text">No</p>
                                                    :
                                                    <p className="applicant-modal-text">Yes</p>
                                                }
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <h5 className="applicant-modal-subheader">
                                                    Parent / Guardian Emergency Contact
                                                </h5>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>First Name</label>
                                                <input type="text" className="form-control" name="guardian_first_name" onChange={handleChange} value={formData.guardian_first_name} />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" name="guardian_last_name" onChange={handleChange} value={formData.guardian_last_name} />
                                            </div>
                                            <div className="col-lg-12 mb-3"></div>
                                            <div className="col-lg-6">
                                                <label>Phone Number </label>
                                                <input type="tel" className="form-control" name="guardian_phone_number" onChange={handleChange} value={formData.guardian_phone_number} />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Relationship</label>
                                                <input type="text" className="form-control" name="guardian_relationship" onChange={handleChange} value={formData.guardian_relationship} />
                                            </div>
                                            <div className="col-lg-12">
                                                <hr />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Notes</label>
                                                <textarea type="text" className="form-control" name="notes" onChange={handleChange} value={formData.notes} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideUpdateApplicantModal} >Close</button>
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={formSubmit}>Save</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                :
                null
            }
            {mediaReleaseModalVisible ?
                <tr>
                    <td>
                        <div className={mediaReleaseModalClass} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Media Release</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideMediaReleaseModal} >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <p><strong>PHOTO AND VIDEO CONSENT, ASSIGNMENT AND RELEASE FORM</strong></p>
                                                <p>PURPOSES: For marketing, advertising, promotional and/or communication purposes, 4Point Taekwondo, from time to time, take photographs and/or video recordings of 4Point Taekwondo based activities or events that include real people, which photographs and video recordings will be placed in the 4Point Taekwondo Photo Bank and which may be used by 4Point Taekwondo, for its own informational, promotional or advertising purposes. For purposes of this Form, “4Point Taekwondo” refers to 4Point Taekwondo Inc. in Canada.</p>
                                                <p>By signing this Form, you are consenting to the taking of photographs and/or video recordings of you by 4Point Taekwondo for the Purposes, you are assigning to 4Point Taekwondo, and waiving any rights you have related to, any such photographs and/or video recordings, and you are consenting to the use of any such photographs and/or video recordings, in whole or in part, by 4Point Taekwondo.</p>
                                                <p>For valuable consideration received but without any promise of remuneration, I hereby agree to allow photographs and/or video recordings to be taken of me, whether posed or candid, while I am on 4Point Taekwondo property and/or participating in 4Point Taekwondo activities or events, to be reproduced, published, displayed, broadcast, transmitted, licensed, sublicensed or otherwise used by 4Point Taekwondo  in connection with the Purposes, including without limitation on 4Point Taekwondo internet web sites, in 4Point Taekwondo printed materials, or in any other materials or medium whatsoever and wherever (the “Work Product”). I confirm that neither 4Point Taekwondo shall be obligated to use the Work Product.</p>
                                                <p>I understand that the Work Product is being created under the direction and control of the 4Point Taekwondo. I hereby irrevocably assign to 4Point Taekwondo any and all rights, including copyright, financial or other rights, and I hereby irrevocably waive in favour 4Point Taekwondo and any Authorized Third Party any and all moral rights or rights of similar nature that I may have in the Work Product. I agree that 4Point Taekwondo has the sole worldwide ownership and rights in and to the Work Product, including copyright interests, and I acknowledge that I have no interest or ownership in the Work Product or its copyright.</p>
                                                <p>I understand that the Work Product is being created under the direction and control of 4Point Taekwondo. I hereby irrevocably assign to 4Point Taekwondo any and all rights, including copyright, financial or other rights, and I hereby irrevocably waive in favour of 4Point Taekwondo any and all moral rights or rights of similar nature that I may have in the Work Product. I agree that 4Point Taekwondo has the sole worldwide ownership and rights in and to the Work Product, including copyright interests, and I acknowledge that I have no interest or ownership in the Work Product or its copyright.</p>
                                                <p>I confirm that I am over the age of majority in my province or territory of residence and am competent to execute this Form and to participate in the development of the Work Product; or, to the extent that I am under the age of majority in my province or territory of residence, have had my parent or guardian review this Form and consent to my participation in the creation of the Work Product on my behalf.</p><p>By signing my name, I (and my legal guardian, where applicable) acknowledge that I (or we) have carefully read and understand this Form.</p>
                                                <br />
                                                <p><strong>ACKNOWLEDGEMENT OF RISK AND RELEASE OF LIABILITY</strong></p>
                                                <p>The following waiver of all claims, release from all liability, assumption of all risks, agreement not to sue and other terms of this agreement are entered into by me on behalf of the undersigned Student (the "Student") with and for the benefit of 4Point Taekwondo Inc., its directors, officers, employees, volunteers, business operators, agents and site property owners or lessees (the "Organization").</p>
                                                <p>1. I am over the Age of Majority and am executing this waiver on behalf of myself or the Student in my capacity as Parent/Guardian&nbsp;with the intent that this waiver be binding on myself and the Student for all purposes.</p>
                                                <p>2. I am aware that there are inherent and significant dangers, hazards and risks (“Risks') associated with the participation in Martial Arts Activities. I am aware that the Risks include but are not limited to injury from physical contact with other students, instructors or equipment performing a skill incorrectly, or potentially dangerous obstacles, conditions or weapons on the floor or vicinity of the Martial Arts Activities. I understand that the Risks are relative to my state of fitness or health (physical, mental and emotional), and to the awareness, care and skill with which the student conducts him or herself while participating in Martial Arts Activities.</p>
                                                <p>3. I freely accept and fully assume all responsibility for all Risks and possibilities of personal injury, death, property damage or loss resulting from my participation in Martial Arts Activities. I freely assume responsibility for my own safety. I agree that although the Organization has taken steps to reduce the Risks and increase the safety of the Martial Arts Activities, it is not possible for the Organization to make the Martial Arts Activities completely safe. I accept these Risks and agree to the terms of this waiver even if the Organization is found to be negligent or in breach of any duty of care or any obligation to me in my participation in Martial Arts Activities.</p>
                                                <p>4. I acknowledge my obligation to inform the nearest employee of the Organization if I feel any pain, discomfort, fatigue or any other symptoms I may suffer during or immediately after my participation in Martial Arts Activities. I understand that I may stop participating at any time, and have the right to immediately withdraw from any exercise or drill in which the conduct of any party seems beyond the scope of training, makes me uncomfortable or which I believe will be harmful to me.</p>
                                                <p>5. I confirm that I have reached the age of majority in the Province or Territory in which I am participating in Martial Arts Activities.</p>
                                                <p>6. In addition to consideration given to the Organization for my participation in Martial Arts Activities, I and my heirs, next of kin, executors, administrators and assigns (my "Legal Representatives") agree:</p>
                                                <p>(a) to waive all claims that I or my Legal Representatives have or may have in the future</p>
                                                <p>against the Organization; and</p>
                                                <p>(b) to release and forever discharge the Organization from all liability for all personal injury, death, property damage or loss resulting from my participation in Martial Arts Activities due to any cause, including but not limited to negligence (failure to use such care as a reasonably prudent and careful person would use under similar circumstances), breach of any duty imposed by law, breach of contract or mistake or error in judgment of the Organization.</p>
                                                <p>7. I agree to be liable for and to hold harmless and indemnify the Organization from all actions, proceedings, claims, damages, costs demands including court costs and costs on a solicitor and own client basis, and liabilities of whatsoever nature or kind arising out of or in any way connected with my participation in Martial Arts Activities.</p>
                                                <p>8. I agree that this waiver and all terms contained within are governed by the laws of the Province or Territory in which I am participating in Martial Arts Activities. I hereby irrevocably submit to the jurisdiction of the courts of that Province or Territory.</p>
                                                <p>9. I confirm that I have had sufficient time to read and understand each term in this waiver in its entirety, and have agreed to the terms freely and voluntarily. I understand that this waiver is binding on myself and my Legal Representatives.</p>
                                            </div>
                                            {formData.media_release_signature != "" ?
                                                <>
                                                    <div className="col-lg-12">
                                                        <hr />
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <h5 className="applicant-modal-subheader">
                                                            Signature
                                                        </h5>
                                                    </div>
                                                    <img src={formData.media_release_signature} className="applicant-modal-image" />
                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideMediaReleaseModal} >Close</button>
                                        <ReactToPrint
                                            trigger={() => <button className="btn btn-primary">Print</button>}
                                            content={() => mediaReleaseRef.current}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                :
                null
            }
            {waiverModalVisible ?
                <tr>
                    <td>
                        <div className={waiverModalClass} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Waiver</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideWaiverModal} >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {applicantAge > 17 ?
                                                    <>
                                                        <h5>Acknowledgement of Risk and Release of Liability (18+)</h5>
                                                        <p>For Students over the Age of Majority in the Province or Territory in which the Martial Arts Activities are provided by the Organization</p>
                                                        <br />
                                                        <h5>WARNING: THIS AGREEMENT WILL AFFECT YOUR LEGAL RIGHTS. READ IT CAREFULLY!</h5>
                                                        <br />
                                                        <p>Every Student Must Read and Understand this Waiver before Participating in Martial Arts Activities</p>
                                                        <br />
                                                        <p>The following waiver of all claims, release from all liability, assumption of all risks, agreement not to sue and other terms of this agreement are entered into by me on behalf of the undersigned Student</p>
                                                        <p>(the "Student") with and for the benefit of 4Point Taekwondo Inc., its directors, officers, employees, volunteers, business operators, agents and site property owners or lessees (the "Organization").</p>
                                                        <p>1. "Martial Arts Activities" includes but is not limited to contact and non-contact martial arts activities, lessons, classes, training, use of facilities, programs and services provided to the Student by the Organization.</p>
                                                        <p>2. I am aware that there are inherent and significant dangers, hazards and risks (“Risks') associated with the participation in Martial Arts Activities. I am aware that the Risks include but are not limited to injury from physical contact with other students, instructors or equipment performing a skill incorrectly, or potentially dangerous obstacles, conditions or weapons on the floor or vicinity of the Martial Arts Activities. I understand that the Risks are relative to my state of fitness or health (physical, mental and emotional), and to the awareness, care and skill with which the student conducts him or herself while participating in Martial Arts Activities.</p>
                                                        <p>3. I freely accept and fully assume all responsibility for all Risks and possibilities of personal injury, death, property damage or loss resulting from my participation in Martial Arts Activities. I freely assume responsibility for my own safety. I agree that although the Organization has taken steps to reduce the Risks and increase the safety of the Martial Arts Activities, it is not possible for the Organization to make the Martial Arts Activities completely safe. I accept these Risks and agree to the terms of this waiver even if the Organization is found to be negligent or in breach of any duty of care or any obligation to me in my participation in Martial Arts Activities.</p>
                                                        <p>4. I acknowledge my obligation to inform the nearest employee of the Organization if I feel any pain, discomfort, fatigue or any other symptoms I may suffer during or immediately after my participation in Martial Arts Activities. I understand that I may stop participating at any time, and have the right to immediately withdraw from any exercise or drill in which the conduct of any party seems beyond the scope of training, makes me uncomfortable or which I believe will be harmful to me.</p>
                                                        <p>5. I confirm that I have reached the age of majority in the Province or Territory in which I am participating in Martial Arts Activities.</p>
                                                        <p>6. In addition to consideration given to the Organization for my participation in Martial Arts Activities, I and my heirs, next of kin, executors, administrators and assigns (my "Legal Representatives") agree:</p><p> </p><p>(a) to waive all claims that I or my Legal Representatives have or may have in the future</p><p>against the Organization; and</p><p> </p><p>(b) to release and forever discharge the Organization from all liability for all personal injury,</p><p>death, property damage or loss resulting from my participation in Martial Arts Activities due to any cause, including but not limited to negligence (failure to use such care as a reasonably prudent and careful person would use under similar circumstances), breach of any duty imposed by law, breach of contract or mistake or error in judgment of the Organization.</p>
                                                        <p>7. I agree to be liable for and to hold harmless and indemnify the Organization from all actions, proceedings, claims, damages, costs demands including court costs and costs on a solicitor and own client basis, and liabilities of whatsoever nature or kind arising out of or in any way connected with my participation in Martial Arts Activities.</p>
                                                        <p>8. I agree that this waiver and all terms contained within are governed by the laws of the Province or Territory in which I am participating in Martial Arts Activities. I hereby irrevocably submit to the jurisdiction of the courts of that Province or Territory.</p>
                                                        <p>9. I confirm that I have had sufficient time to read and understand each term in this waiver in its entirety, and have agreed to the terms freely and voluntarily. I understand that this waiver is binding on myself and my Legal Representatives.</p>

                                                    </>
                                                    :
                                                    <>
                                                        <h5>Acknowledgement of Risk and Release of Liability (Under 18)</h5>
                                                        <p>For Students under the Age of Majority in the Province or Territory in which the Martial Arts Activities are provided by the Organization</p>
                                                        <br />
                                                        <h5>WARNING: THIS AGREEMENT WILL AFFECT YOUR LEGAL RIGHTS. READ IT CAREFULLY!</h5>
                                                        <br />
                                                        <p>Every Parent/Guardian Must Read and Understand this Waiver Prior to the Student Participating in Martial Arts Activities</p>
                                                        <br />
                                                        <p>The following waiver of all claims, release from all liability, assumption of all risks, agreement not to sue and other terms of this agreement are entered into by me on behalf of the undersigned Student (the "Student") with and for the benefit of 4Point Taekwondo Inc., its directors, officers, employees, volunteers, business operators, agents and site property owners or lessees (the "Organization").</p>
                                                        <p>1. I am the Parent/Guardian of the Student and am executing this waiver on behalf of the Student in my capacity as Parent/Guardian and with the intent that this waiver be binding on myself and the Student for all purposes.</p>
                                                        <p>2. "Martial Arts Activities" includes but is not limited to contact and non-contact martial arts activities, lessons, classes, training, use of facilities, programs and services provided to the Student by the Organization.</p>
                                                        <p>3. I am aware that there are inherent and significant dangers, hazards and risks (“Risks") associated with the participation in Martial Arts Activities. I am aware that the Risks include but are not limited to injury from physical contact with other students, instructors or equipment, performing a skill incorrectly, or potentially dangerous obstacles, conditions or weapons on the floor or vicinity of the Martial Arts Activities. I understand that the Risks are relative to the Student's state of fitness or health (physical, mental and emotional), and to the awareness, care and skill with which the student conducts him or herself while participating in Martial Arts Activities.</p>
                                                        <p>4. I freely accept and fully assume all responsibility for all Risks and possibilities of personal injury, death, property damage or loss resulting from the Student's participation in Martial Arts Activities. I agree that although the Organization has taken steps to reduce the Risks and increase the safety of the Martial Arts Activities, it is not possible for the Organization to make the Martial Arts Activities completely safe. I accept these Risks and agree to the terms of this waiver even if the Organization is found to be negligent or in breach of any duty of care or any obligation to the Student in the Student's participation in Martial Arts Activities.</p>
                                                        <p>5. I acknowledge the Student's obligation to inform the nearest employee of the Organization if the Student feels any pain, discomfort, fatigue or any other symptoms the Student may suffer during or immediately after his or her participation in Martial Arts Activities. I understand that the Student may stop participating at any time, and has the right to immediately withdraw from any exercise or drill in which the conduct of any party seems beyond the scope of training, makes the Student uncomfortable or which the Student believes will be harmful to him or her.</p>
                                                        <p>6. In addition to consideration given to the Organization for the Student's participation in  Martial Arts Activities, I and my heirs, next of kin, executors, administrators and assigns, as well as the Student's heirs, next of kin, executors, administrators and assigns (collectively our “Legal Representatives") agree:</p><p> </p><p>(a) to waive all claims that I, the Student or our Legal Representatives have or may have in the</p><p>future against the Organization; and</p><p> </p><p>(b) to release and forever discharge the Organization from all liability for all personal injury, death, property damage or loss resulting from the Student's participation in Martial Arts Activities due to any cause, including but not limited to negligence (failure to use such care as a reasonably prudent and careful person would use under similar circumstances), breach of any duty imposed by law, breach of contract or mistake or error in judgment of the Organization.</p>
                                                        <p>7. I agree to be liable for and to hold harmless and indemnify the Organization from all actions, proceedings, claims, damages, costs demands including court costs and costs on a solicitor and own client basis, and liabilities of whatsoever nature or kind arising out of or in any way connected with the Student's participation in Martial Arts Activities.</p>
                                                        <p>8. I agree that this waiver and all terms contained within are governed by the laws of the Province or Territory in which the Student is participating in Martial Arts Activities. I hereby irrevocably submit to the jurisdiction of the courts of that Province or Territory.</p>
                                                        <p>9. I confirm that I have had sufficient time to read and understand each term in this waiver in its entirety, and have agreed to the terms freely and voluntarily. I understand that this waiver is binding on myself, the Student and our Legal Representatives.</p>

                                                    </>
                                                }
                                                <div className="col-lg-12">
                                                    <hr />
                                                </div>
                                                <div className="col-lg-12">
                                                    <h5 className="applicant-modal-subheader">
                                                        Signature
                                                    </h5>
                                                    <img src={formData.signature} className="applicant-modal-image" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideWaiverModal} >Close</button>
                                        <ReactToPrint
                                            trigger={() => <button className="btn btn-primary">Print</button>}
                                            content={() => waiverRef.current}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                :
                null
            }
            <tr>
                <td scope="row">
                    {first_name} {last_name}
                </td>
                <td className="email-address">
                    {email_address}<br />
                    {phone_number}
                </td>
                <td>
                    {gender}
                </td>
                <td>
                    {city}
                </td>
                <td>
                    <div className="notes tooltip-notes">
                        {notes != "" ? notes : "No notes found in the database."}
                        <span className="tooltiptext-notes">
                            {notes != "" ? notes : "No notes found in the database"}
                        </span>
                    </div>
                </td>
                <td>
                    {media_release ==1 ? "Yes":"No"} 
                </td>
                <td className="view-icon-container">
                    <button className="view-button" onClick={showApplicantModal} value={id}>
                        <BsEyeFill className="view-icon"/>
                    </button>
                </td>
            </tr>
        </>
    );
};

export default ApplicantRow;