import React from 'react'
import { useHistory } from 'react-router-dom'
import Meta from '../components/Meta'
import Application from '../components/Applications'
import '../css/ApplicationStyles.css'

const Applications = () => {
    let history = useHistory();
    const loggedIn = sessionStorage.getItem('loggedIn');
    console.log(loggedIn);
    // page content
    const pageTitle = 'Applications'

  return (
    <div id="applications-page">
      <Meta title={pageTitle}/>
      {loggedIn !== "true" ?
          history.push("/login")
          :
          <Application />
      }
    </div>
  )
}

export default Applications
