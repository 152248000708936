import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillPencilFill, BsEyeFill } from 'react-icons/bs'
import { FaTrash, FaPlus } from 'react-icons/fa'
import { IoInformationCircleOutline } from 'react-icons/io5'


const initialFormData = Object.freeze({
    name: "",
    description: "",
    start_time: "",
    end_time: "",
    day: "",
});

const initialAddFormData = Object.freeze({
    name: "",
    description: "",
    start_time: "",
    end_time: "",
    day: "",
});

const AllClasses = () => {
    let history = useHistory();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const [classesCount, setClassesCount] = useState(0);
    const [classesCountMon, setClassesCountMon] = useState(0);
    const [classesCountTue, setClassesCountTue] = useState(0);
    const [classesCountWed, setClassesCountWed] = useState(0);
    const [classesCountThu, setClassesCountThu] = useState(0);
    const [classesCountFri, setClassesCountFri] = useState(0);
    const [classesCountSat, setClassesCountSat] = useState(0);
    const [classesCountSun, setClassesCountSun] = useState(0);
    // const [classes, setClasses] = useState([]);
    const [classesMon, setClassesMon] = useState([]);
    const [classesTue, setClassesTue] = useState([]);
    const [classesWed, setClassesWed] = useState([]);
    const [classesThu, setClassesThu] = useState([]);
    const [classesFri, setClassesFri] = useState([]);
    const [classesSat, setClassesSat] = useState([]);
    const [classesSun, setClassesSun] = useState([]);
    const [classesModalClass, setClassesModalClass] = useState("modal fade");
    const [updateClassModalVisible, setupdateClassModalVisible] = useState(false);
    const [classesModalVisible, setClassesModalVisible] = useState(false);
    const [formStatus, setFormStatus] = useState("standby");
    const [reloadCount, setReloadCount] = useState(1);
    const loggedIn = sessionStorage.getItem("loggedIn");
    const userName = userData.user.name;
    const [addClassesModalVisible, setAddClassesModalVisible] = useState(false);
    const [classesReady, setClassesReady] = useState(false);
    const [isDescriptionShown, setIsDescriptionShown] = useState(false);
    // const { id, first_name, last_name, applicants_number } = props.applicant;

    //Add this for toast not showing
    toast.configure();
    const [formData, setFormData] = useState(initialFormData);
    const [formAddData, setAddFormData] = useState(initialAddFormData);

    const showCategoriesModal = (e) => {
        setClassesModalClass("modal fade applicant-modal show");
        setClassesModalVisible(true);
    }

    const hideCategoriesModal = (e) => {
        setClassesModalClass("modal fade applicant-modal");
        setShowCategoriesPrintable(false);
        setClassesModalVisible(false);
        setAddClassesModalVisible(false);
    }

    const showUpdateCategoriesModal = (e) => {
        setClassesModalClass("modal fade applicant-modal show");
        setupdateClassModalVisible(true);
    }

    const hideUpdateCategoriesModal = (e) => {
        setClassesModalClass("modal fade applicant-modal");
        setupdateClassModalVisible(false);
        setClassesModalClass("modal fade applicant-modal");
        setClassesModalVisible(false);
    }

    const addCategoriesModal = (e) => {
        setAddClassesModalVisible("modal fade applicant-modal show");
        setupdateClassModalVisible(false);
        setClassesModalVisible(false);
    }

    const showAddCategoriesModal = (e) => {
        setAddClassesModalVisible("modal fade applicant-modal show");
        setupdateClassModalVisible(false);
        setAddClassesModalVisible(true);
    }

    const hideAddCategoriesModal = (e) => {
        setClassesModalClass("modal fade applicant-modal");
        setupdateClassModalVisible(false);
        setClassesModalClass("modal fade applicant-modal");
        setClassesModalVisible(false);
        setAddClassesModalVisible(false);
    }

    const retrieveClasses = async () => {
        const response = fetch('https://app.4pointtkd-members.com/api/schedule/summary', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            headers: {
                //'Access-Control-Allow-Origin': "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'client', // no-referrer, *client
        })
            .then((response) => {
                return response.json();
            })
            .then((events) => {
                return events.data;
            });
        return response;
    };

    const retrieveClass = async (e) => {
        const response = fetch('https://app.4pointtkd-members.com/api/schedule/' + e, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            headers: {
                //'Access-Control-Allow-Origin': "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'client', // no-referrer, *client
        })
            .then((response) => {
                return response.json();
            })
            .then((events) => {
                return events.data;
            });
        return response;
    };

    const deleteCategory = async (e) => {
        const response = await fetch('https://app.4pointtkd-members.com/api/category/' + e, {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            headers: {
                //'Access-Control-Allow-Origin': "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'client', // no-referrer, *client
        });
        // return response;

        // console.log(response);
        const json = await response.json();

        if (json.status == "Success") {
            //history.push("/thank-you-for-signing-up");
            // alert("Class deleted successfully");
            toast.success('Category deleted successfully', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setReloadCount(reloadCount + 1);
            // window.location.reload();
        } else {
            alert("Deleting category failed!");
            toast.error('Deleting category failed!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setReloadCount(reloadCount + 1);
        }
    };

    useEffect(() => {
        const getClasses = async () => {
            const selectedClasses = await retrieveClasses();
            if (selectedClasses) {
                console.log("Selected Categories", selectedClasses.days);
                setClassesReady(true);
                // setClasses(selectedCategories);
                setClassesMon(selectedClasses.days.monday);
                setClassesTue(selectedClasses.days.tuesday)
                setClassesWed(selectedClasses.days.wednesday)
                setClassesThu(selectedClasses.days.thursday)
                setClassesFri(selectedClasses.days.friday)
                setClassesSat(selectedClasses.days.saturday)
                setClassesSun(selectedClasses.days.sunday)
                // setClassesCount(selectedCategories.length);
                setClassesCountMon(selectedClasses.days.monday.length);
                setClassesCountTue(selectedClasses.days.tuesday.length);
                setClassesCountWed(selectedClasses.days.wednesday.length);
                setClassesCountThu(selectedClasses.days.thursday.length);
                setClassesCountFri(selectedClasses.days.friday.length);
                setClassesCountSat(selectedClasses.days.saturday.length);
                setClassesCountSun(selectedClasses.days.sunday.length);
            } else {
                setClassesReady(true);
            }
        };
        getClasses();
        // console.log("classes", classes);
    }, [reloadCount]);

    const getClassData = (e) => {
        const getCategory = async (e) => {
            const selectedClass = await retrieveClass(e);
            // console.log(selectedCategory)
            setFormData({
                ...formData,
                id: selectedClass.id,
                name: selectedClass.name,
                start_time: selectedClass.start_time,
                end_time: selectedClass.end_time,
                day: selectedClass.day,
                description: selectedClass.description,
            });
            // console.log("length of gallery:", selectedGallery.length);
        }
        getCategory(e);
    }

    const showModal = (e) => {
        setModalClass("modal fade show");
        const applicantId = e.target.value;
    }

    const hideModal = (e) => {
        setModalClass("modal fade");
    }

    const logOut = (e) => {
        sessionStorage.clear();
        history.push("/login");
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        //console.log(formData);
    };

    const handleChangeAdd = (e) => {
        setAddFormData({
            ...formAddData,
            [e.target.name]: e.target.value,
        });
        //console.log(formData);
    };

    const preventDefault = (e) => {
        e.preventDefault();
    }

    async function formSubmit(e) {
        //console.log(formData);
        e.preventDefault();
        if (formData.name != "" && formData.start_time != "" && formData.end_time != "" && formData.description && formData.day != "") {
            setFormStatus("loading");
            const response = await fetch("https://app.4pointtkd-members.com/api/schedule/" + formData.id, {
                method: "PUT",
                mode: "cors",
                cache: "no-cache",
                credentials: "omit",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrer: "client",
                body: JSON.stringify(formData),
            });

            const json = await response.json();

            if (json.status == "Success") {
                //history.push("/thank-you-for-signing-up");
                // alert("Class updated successfully");
                toast.success('Class Schedule updated successfully!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // window.location.reload();
                setFormStatus("standby");
                setReloadCount(reloadCount + 1);
                setupdateClassModalVisible(false);
            } else {
                alert("Class Schedule update failed!");
                setFormStatus("standby");

            }
            console.log("Classes Form Data", formData);
        } else {
            // alert("Please fill up category name!");
            setFormStatus("standby");
            toast.error('Please fill up all fields!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function formAddSubmit(e) {
        console.log("Add Class", formAddData);
        e.preventDefault();
        if (formAddData.name != "" && formAddData.start_time != "" && formAddData.end_time != "" && formAddData.description && formAddData.day != "") {
            setFormStatus("loading");
            const response = await fetch("https://app.4pointtkd-members.com/api/schedule", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "omit",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrer: "client",
                body: JSON.stringify(formAddData),
            });

            const json = await response.json();

            if (json.status == "Success") {
                //history.push("/thank-you-for-signing-up");
                // alert("Class added successfully");
                setFormStatus("standby");
                toast.success('Class added successfully!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // window.location.reload();

                setReloadCount(reloadCount + 1);
                setAddClassesModalVisible(false);
            } else {
                // alert("Adding category failed!");
                setFormStatus("standby");
                toast.error('Adding class failed!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }
        } else {
            // alert("Please fill up category name!");
            toast.error('Please fill up all fields!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setFormStatus("standby");
        }
    }

    return (
        <>
            {updateClassModalVisible ?
                <tr>
                    <td>
                        <div className="modal fade applicant-modal show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="exampleModalLabel">Edit Class Details</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideUpdateCategoriesModal} >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <label>Class Name</label>
                                                <input type="text" className="form-control" name="name" onChange={handleChange} value={formData.name} />
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label>Day of the Week</label>
                                                {/* <input type="text" className="form-control" name="day_of_week" onChange={handleChangeAdd} /> */}
                                                <div className="select">
                                                    <select name="day" id="day-of-week-select" onChange={handleChange} className="form-control" value={formData.day}>
                                                        <option value="" selected hidden>---</option>
                                                        <option value="Monday">Monday</option>
                                                        <option value="Tuesday">Tuesday</option>
                                                        <option value="Wednesday">Wednesday</option>
                                                        <option value="Thursday">Thursday</option>
                                                        <option value="Friday">Friday</option>
                                                        <option value="Saturday">Saturday</option>
                                                        <option value="Sunday">Sunday</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>Start Time</label>
                                                <input type="time" className="form-control" name="start_time" onChange={handleChange} value={formData.start_time} />
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>End Time</label>
                                                <input type="time" className="form-control" name="end_time" onChange={handleChange} value={formData.end_time} />
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label>Class Description</label>
                                                <input type="text" className="form-control" name="description" onChange={handleChange} value={formData.description} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideUpdateCategoriesModal} >Close</button>
                                        {formStatus != "standby" ?
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={preventDefault}>Saving...</button>
                                            :
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={formSubmit}>Save</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                :
                null
            }

            {addClassesModalVisible ?
                <tr>
                    <td>
                        <div className="modal fade applicant-modal show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="exampleModalLabel">Add New Class</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideAddCategoriesModal} >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <label>Class Name</label>
                                                <input type="text" className="form-control" name="name" onChange={handleChangeAdd} />
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label>Day of the Week</label>
                                                {/* <input type="text" className="form-control" name="day_of_week" onChange={handleChangeAdd} /> */}
                                                <div className="select">
                                                    <select name="day" id="day-of-week-select" onChange={handleChangeAdd} className="form-control">
                                                        <option value="" selected hidden>---</option>
                                                        <option value="Monday">Monday</option>
                                                        <option value="Tuesday">Tuesday</option>
                                                        <option value="Wednesday">Wednesday</option>
                                                        <option value="Thursday">Thursday</option>
                                                        <option value="Friday">Friday</option>
                                                        <option value="Saturday">Saturday</option>
                                                        <option value="Sunday">Sunday</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>Start Time</label>
                                                <input type="time" className="form-control" name="start_time" onChange={handleChangeAdd} />
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>End Time</label>
                                                <input type="time" className="form-control" name="end_time" onChange={handleChangeAdd} />
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <label>Class Description</label>
                                                <input type="text" className="form-control" name="description" onChange={handleChangeAdd} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideAddCategoriesModal} >Close</button>
                                        {formStatus != "standby" ?
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={preventDefault}>Adding...</button>
                                            :
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={formAddSubmit}>Add</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                :
                null
            }

            {loggedIn === "true" ?
                <Container>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-xs-12">
                            <div className="right-sideabr card">
                                <center>
                                    <img className="user-image" src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" alt="Profile Image" />
                                    <h4>
                                        {userName}
                                        <br />
                                    </h4>
                                </center>
                                <ul className="list-item">
                                    <li>
                                        <a href="/applications">
                                            Applications
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/categories">
                                            Categories
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/classes">
                                            Classes
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/category-changes">
                                            Category Changes
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={logOut}>
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row  mb-4">
                                <div className="col-lg-6">
                                    <h2 className="mb-3">Classes</h2>
                                </div>
                                <div className="col-lg-6  text-right">
                                    <button type="button" className="record-action new-category-btn" data-dismiss="modal" onClick={function () { showAddCategoriesModal(); }}><FaPlus className="plus-icon" /> New Class</button>
                                </div>
                            </div>

                            <div className="row">
                                {classesReady ?
                                    <>

                                        <div className="col-lg-6">
                                            <div className="classes-container card">
                                                <h2 className="mb-3">Monday</h2>
                                                <div className="title-divider"></div>
                                                <div className="schedule-container">

                                                    {classesCountMon > 0 ?
                                                        classesMon.map((schedule, index) => {
                                                            return (
                                                                <>
                                                                    <div className="classes-schedule" key={index}>
                                                                        <div className="title-box">
                                                                            <div className="col-md-5">
                                                                                <h6 className="schedule-time" key={index}>{new Date('1970-01-01T' + schedule.start_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })} - {new Date('1970-01-01T' + schedule.end_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}</h6>
                                                                            </div>
                                                                            <div className="col-md-4 tooltip-container">
                                                                                <div className="tooltip-notes">
                                                                                    <h5 className="classes-name">{schedule.name}</h5>
                                                                                    <p className="tooltiptext-notes schedule-description" key={index}>{schedule.description}</p>

                                                                                </div>
                                                                                <IoInformationCircleOutline />
                                                                            </div>
                                                                            <div className="col-md-3 actions-container">
                                                                                <button className="category-btn" onClick={function () { showUpdateCategoriesModal(); getClassData(schedule.id) }} value={schedule.id}><BsFillPencilFill /></button>
                                                                                <a href={`/view-class?day=${schedule.day}&schedule_id=${schedule.id}`}>
                                                                                    <button className="view-button" value="">
                                                                                        <BsEyeFill className="view-icon" />
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                        :
                                                        <div className='col-lg-12'>
                                                            <p className="text-center">No records found</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-lg-6">
                                            <div className="classes-container card">
                                                <h2 className="mb-3">Tuesday</h2>
                                                <div className="title-divider"></div>
                                                <div className="schedule-container">
                                                    {classesCountTue > 0 ?
                                                        classesTue.map((schedule, index) => {
                                                            return (
                                                                <>
                                                                    <div className="classes-schedule" key={index}>
                                                                        <div className="title-box">
                                                                            <div className="col-md-5">
                                                                                <h6 className="schedule-time" key={index}>{new Date('1970-01-01T' + schedule.start_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })} - {new Date('1970-01-01T' + schedule.end_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}</h6>
                                                                            </div>
                                                                            <div className="col-md-4 tooltip-container">
                                                                                <div className="tooltip-notes">
                                                                                    <h5 className="classes-name">{schedule.name}</h5>
                                                                                   <p className="tooltiptext-notes schedule-description" key={index}>{schedule.description}</p>

                                                                                </div>
                                                                                <IoInformationCircleOutline />
                                                                            </div>
                                                                            <div className="col-md-3 actions-container">
                                                                                <button className="category-btn" onClick={function () { showUpdateCategoriesModal(); getClassData(schedule.id) }} value={schedule.id}><BsFillPencilFill /></button>
                                                                                <a href={`/view-class?day=${schedule.day}&schedule_id=${schedule.id}`}>
                                                                                    <button className="view-button" value="">
                                                                                        <BsEyeFill className="view-icon" />
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                        :
                                                        <div className='col-lg-12'>
                                                            <p className="text-center">No records found</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="classes-container card">
                                                <h2 className="mb-3">Wednesday</h2>
                                                <div className="title-divider"></div>
                                                <div className="schedule-container">
                                                    {classesCountWed > 0 ?
                                                        classesWed.map((schedule, index) => {
                                                            return (
                                                                <>
                                                                    <div className="classes-schedule" key={index}>
                                                                        <div className="title-box">
                                                                            <div className="col-md-5">
                                                                                <h6 className="schedule-time" key={index}>{new Date('1970-01-01T' + schedule.start_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })} - {new Date('1970-01-01T' + schedule.end_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}</h6>
                                                                            </div>
                                                                            <div className="col-md-4 tooltip-container">
                                                                                <div className="tooltip-notes">
                                                                                    <h5 className="classes-name">{schedule.name}</h5>
                                                                                   <p className="tooltiptext-notes schedule-description" key={index}>{schedule.description}</p>

                                                                                </div>
                                                                                <IoInformationCircleOutline />
                                                                            </div>
                                                                            <div className="col-md-3 actions-container">
                                                                                <button className="category-btn" onClick={function () { showUpdateCategoriesModal(); getClassData(schedule.id) }} value={schedule.id}><BsFillPencilFill /></button>
                                                                                <a href={`/view-class?day=${schedule.day}&schedule_id=${schedule.id}`}>
                                                                                    <button className="view-button" value="">
                                                                                        <BsEyeFill className="view-icon" />
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                        :
                                                        <div className='col-lg-12'>
                                                            <p className="text-center">No records found</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="classes-container card">
                                                <h2 className="mb-3">Thursday</h2>
                                                <div className="title-divider"></div>
                                                <div className="schedule-container">
                                                    {classesCountThu > 0 ?
                                                        classesThu.map((schedule, index) => {
                                                            return (
                                                                <>
                                                                    <div className="classes-schedule" key={index}>
                                                                        <div className="title-box">
                                                                            <div className="col-md-5">
                                                                                <h6 className="schedule-time" key={index}>{new Date('1970-01-01T' + schedule.start_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })} - {new Date('1970-01-01T' + schedule.end_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}</h6>
                                                                            </div>
                                                                            <div className="col-md-4 tooltip-container">
                                                                                <div className="tooltip-notes">
                                                                                    <h5 className="classes-name">{schedule.name}</h5>
                                                                                   <p className="tooltiptext-notes schedule-description" key={index}>{schedule.description}</p>

                                                                                </div>
                                                                                <IoInformationCircleOutline />
                                                                            </div>
                                                                            <div className="col-md-3 actions-container">
                                                                                <button className="category-btn" onClick={function () { showUpdateCategoriesModal(); getClassData(schedule.id) }} value={schedule.id}><BsFillPencilFill /></button>
                                                                                <a href={`/view-class?day=${schedule.day}&schedule_id=${schedule.id}`}>
                                                                                    <button className="view-button" value="">
                                                                                        <BsEyeFill className="view-icon" />
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                        :
                                                        <div className='col-lg-12'>
                                                            <p className="text-center">No records found</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="classes-container card">
                                                <h2 className="mb-3">Friday</h2>
                                                <div className="title-divider"></div>
                                                <div className="schedule-container">
                                                    {classesCountFri > 0 ?
                                                        classesFri.map((schedule, index) => {
                                                            return (
                                                                <>
                                                                    <div className="classes-schedule" key={index}>
                                                                        <div className="title-box">
                                                                            <div className="col-md-5">
                                                                                <h6 className="schedule-time" key={index}>{new Date('1970-01-01T' + schedule.start_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })} - {new Date('1970-01-01T' + schedule.end_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}</h6>
                                                                            </div>
                                                                            <div className="col-md-4 tooltip-container">
                                                                                
                                                                                <div className="tooltip-notes">
                                                                                    <h5 className="classes-name">{schedule.name}</h5>
                                                                                   <p className="tooltiptext-notes schedule-description" key={index}>{schedule.description}</p>
                                                                                </div>
                                                                                <IoInformationCircleOutline />
                                                                            </div>
                                                                            <div className="col-md-3 actions-container">
                                                                                <button className="category-btn" onClick={function () { showUpdateCategoriesModal(); getClassData(schedule.id) }} value={schedule.id}><BsFillPencilFill /></button>
                                                                                <a href={`/view-class?day=${schedule.day}&schedule_id=${schedule.id}`}>
                                                                                    <button className="view-button" value="">
                                                                                        <BsEyeFill className="view-icon" />
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                        :
                                                        <div className='col-lg-12'>
                                                            <p className="text-center">No records found</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="classes-container card">
                                                <h2 className="mb-3">Saturday</h2>
                                                <div className="title-divider"></div>
                                                <div className="schedule-container">
                                                    {classesCountSat > 0 ?
                                                        classesSat.map((schedule, index) => {
                                                            return (
                                                                <>
                                                                    <div className="classes-schedule" key={index}>
                                                                        <div className="title-box">
                                                                            <div className="col-md-5">
                                                                                <h6 className="schedule-time" key={index}>{new Date('1970-01-01T' + schedule.start_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })} - {new Date('1970-01-01T' + schedule.end_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}</h6>
                                                                            </div>
                                                                            <div className="col-md-4 tooltip-container">
                                                                                <div className="tooltip-notes">
                                                                                    <h5 className="classes-name">{schedule.name}</h5>
                                                                                   <p className="tooltiptext-notes schedule-description" key={index}>{schedule.description}</p>

                                                                                </div>
                                                                                <IoInformationCircleOutline />
                                                                            </div>
                                                                            <div className="col-md-3 actions-container">
                                                                                <button className="category-btn" onClick={function () { showUpdateCategoriesModal(); getClassData(schedule.id) }} value={schedule.id}><BsFillPencilFill /></button>
                                                                                <a href={`/view-class?day=${schedule.day}&schedule_id=${schedule.id}`}>
                                                                                    <button className="view-button" value="">
                                                                                        <BsEyeFill className="view-icon" />
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                        :
                                                        <div className='col-lg-12'>
                                                            <p className="text-center">No records found</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="classes-container card">
                                                <h2 className="mb-3">Sunday</h2>
                                                <div className="title-divider"></div>
                                                <div className="schedule-container">
                                                    {classesCountSun > 0 ?
                                                        classesSun.map((schedule, index) => {
                                                            return (
                                                                <>
                                                                    <div className="classes-schedule" key={index}>
                                                                        <div className="title-box">
                                                                            <div className="col-md-5">
                                                                                <h6 className="schedule-time" key={index}>{new Date('1970-01-01T' + schedule.start_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })} - {new Date('1970-01-01T' + schedule.end_time + 'Z').toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}</h6>
                                                                            </div>
                                                                            <div className="col-md-4 tooltip-container">
                                                                                <div className="tooltip-notes">
                                                                                    <h5 className="classes-name">{schedule.name}</h5>
                                                                                   <p className="tooltiptext-notes schedule-description" key={index}>{schedule.description}</p>

                                                                                </div>
                                                                                <IoInformationCircleOutline />
                                                                            </div>
                                                                            <div className="col-md-3 actions-container">
                                                                                <button className="category-btn" onClick={function () { showUpdateCategoriesModal(); getClassData(schedule.id) }} value={schedule.id}><BsFillPencilFill /></button>
                                                                                <a href={`/view-class?day=${schedule.day}&schedule_id=${schedule.id}`}>
                                                                                    <button className="view-button" value="">
                                                                                        <BsEyeFill className="view-icon" />
                                                                                    </button>
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                        :
                                                        <div className='col-lg-12'>
                                                            <p className="text-center">No records found</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <div className='col-lg-12'>
                                        <p className="text-center">Loading...</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
                :
                null
            }
        </>
    )

}

export default AllClasses