import Meta from '../components/Meta'
import '../css/ApplicationStyles.css'
import { Container } from 'react-bootstrap'
import CategoryLog from '../components/CategoryLog'

const CategoryChanges = () => {
  const pageTitle = 'Category Changes'
  
  return (
    <div id="categories-page">
      <Meta title={pageTitle} />
      <CategoryLog />
    </div>
  )
}

export default CategoryChanges
