import Meta from '../components/Meta'
import LoginForm from '../components/Forms/LoginForm'

const Home = () => {
  // page content
  const pageTitle = 'Login'
  //const pageDescription = ''

  return (
    <div>
      <Meta title={pageTitle}/>
      <LoginForm />
    </div>
  )
}

export default Home
