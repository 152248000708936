import Meta from '../components/Meta'

const ThankYou = () => {
  // page content
  const pageTitle = 'Thank you for signing up'

  return (
      <div>
        <Meta title={pageTitle}/>
        <div className="container mt-5 thank-you-container">
            <div className="starter-template text-center">
                <h1>Thank you for signing up</h1>
                <p className="lead text-capitalize">Our staff will contact you shortly.</p>
                <a href="https://4pointtkd.com/">
                    <button className="btn-thank-you">LEARN MORE</button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default ThankYou
