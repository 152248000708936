import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillPencilFill } from 'react-icons/bs'
import { FaTrash, FaPlus } from 'react-icons/fa'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Helmet from 'react-helmet';

const initialFormData = Object.freeze({
  day: "",
  start_time: "",
  end_time: "",
  description: ""

});

const initialAddFormData = Object.freeze({
  application_id: "",
  schedule_id: "",
  date: "",
});

const ViewClass = () => {
  let history = useHistory();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [classesCount, setClassesCount] = useState(0);
  const [classes, setClasses] = useState([{}]);
  const [attendeesCount, setAttendeesCount] = useState(0);
  const [attendees, setAttendees] = useState([{}]);
  const [classesModalClass, setClassesModalClass] = useState("modal fade");
  const [updateClassesModalVisible, setUpdateClassesModalVisible] = useState(false);
  const [classesModalVisible, setClassesModalVisible] = useState(false);
  const [formStatus, setFormStatus] = useState("standby");
  const [reloadCount, setReloadCount] = useState(1);
  const loggedIn = sessionStorage.getItem("loggedIn");
  const userName = userData.user.name;
  const [addClassesModalVisible, setAddClassesModalVisible] = useState(false);
  const [classesReady, setClassesReady] = useState(false);
  const [attendeesReady, setAttendeesReady] = useState(false);
  const [searchedArray, setSearchedArray] = useState(classes);
  const [searchString, setSearchString] = useState("");
  const [startDate, setStartDate] = useState(null);
 

  console.log("Start Date", startDate);
  // const { id, first_name, last_name, applicants_number } = props.applicant;
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const dayOfWeek = query.get('day');
  const classScheduleID = query.get('schedule_id');

  const isDayAvailable = (date) => {
    const day =  date.getDay();
    if(dayOfWeek == "Monday") {
      return day === 1;
    }

    if(dayOfWeek == "Tuesday") {
      return day === 2;
    }

    if(dayOfWeek == "Wednesday") {
      return day === 3;
    }

    if(dayOfWeek == "Thursday") {
      return day === 4;
    } 

    if(dayOfWeek == "Friday") {
      return day === 5;
    }
    
  };

  //Add this for toast not showing
  toast.configure();
  const [formData, setFormData] = useState(initialFormData);
  const [formAddData, setAddFormData] = useState(initialAddFormData);

  const showCategoriesModal = (e) => {
    setClassesModalClass("modal fade applicant-modal show");
    setClassesModalVisible(true);
  }

  const hideCategoriesModal = (e) => {
    setClassesModalClass("modal fade applicant-modal");
    setShowCategoriesPrintable(false);
    setClassesModalVisible(false);
    setAddClassesModalVisible(false);
  }

  const showUpdateCategoriesModal = (e) => {
    setClassesModalClass("modal fade applicant-modal show");
    setUpdateClassesModalVisible(true);
  }

  const hideUpdateCategoriesModal = (e) => {
    setClassesModalClass("modal fade applicant-modal");
    setUpdateClassesModalVisible(false);
    setClassesModalClass("modal fade applicant-modal");
    setClassesModalVisible(false);
  }

  const addCategoriesModal = (e) => {
    setAddClassesModalVisible("modal fade applicant-modal show");
    setUpdateClassesModalVisible(false);
    setClassesModalVisible(false);
  }

  const showAddCategoriesModal = (e) => {
    setAddClassesModalVisible("modal fade applicant-modal show");
    setUpdateClassesModalVisible(false);
    setAddClassesModalVisible(true);
  }

  const hideAddCategoriesModal = (e) => {
    setClassesModalClass("modal fade applicant-modal");
    setUpdateClassesModalVisible(false);
    setClassesModalClass("modal fade applicant-modal");
    setClassesModalVisible(false);
    setAddClassesModalVisible(false);
  }

  const retrieveCategories = async () => {
    const response = fetch('https://app.4pointtkd-members.com/api/category', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveAttendees = async (e) => {
    const response = fetch('https://app.4pointtkd-members.com/api/schedule/' + classScheduleID + "/attendees", {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };


  const retrieveClassStudents = async (e) => {
    const response = fetch('https://app.4pointtkd-members.com/api/application?order_by=last_name', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const deleteCategory = async (e) => {
    const response = await fetch('https://app.4pointtkd-members.com/api/category/' + e, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    });
    // return response;

    // console.log(response);
    const json = await response.json();

    if (json.status == "Success") {
      //history.push("/thank-you-for-signing-up");
      // alert("Category deleted successfully");
      toast.success('Category deleted successfully', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setReloadCount(reloadCount + 1);
      // window.location.reload();
    } else {
      alert("Deleting category failed!");
      toast.error('Deleting category failed!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setReloadCount(reloadCount + 1);
    }
  };

  useEffect(() => {
    const getStudents = async () => {
      const selectedStudents = await retrieveClassStudents();
      if (selectedStudents) {
        console.log("Selected Students", selectedStudents);
        setClassesReady(true);
        // setClasses(selectedStudents);
        setClasses(selectedStudents);
        setClassesCount(selectedStudents.length);
      } else {
        setClassesReady(true);
      }
    };
    getStudents();
    getAttendees();
  }, [reloadCount]);

    const getAttendees = async (e) => {
      const selectedAttendees = await retrieveAttendees();
      if (selectedAttendees) {
        setAttendees(selectedAttendees.schedule_students);
        setAttendeesReady(true);
        // setClasses(selectedStudents);
        setAttendeesCount(selectedAttendees.schedule_students.length);
      }

      // console.log(selectedCategory)
      setFormData({
        ...formData,
        id: selectedAttendees.id,
        name: selectedAttendees.name,
        end_time: selectedAttendees.end_time,
        start_time: selectedAttendees.start_time,
        description: selectedAttendees.description
      });
      // console.log("length of gallery:", selectedGallery.length);
    }

  const showModal = (e) => {
    setModalClass("modal fade show");
    const applicantId = e.target.value;
  }

  const hideModal = (e) => {
    setModalClass("modal fade");
  }

  const logOut = (e) => {
    sessionStorage.clear();
    history.push("/login");
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    //console.log(formData);
  };

  const handleChangeAdd = (e) => {
    setAddFormData({
      ...formAddData,
      [e.target.name]: e.target.value,
      schedule_id: classScheduleID,
    });
    console.log("Form Add Student", formAddData);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  }

  var date = new Date('2020-11-06T01:30:00.000Z');
console.log(date.toLocaleDateString());
console.log(date.toLocaleString());
console.log(date.toLocaleTimeString());

  async function formSubmit(e) {
    //console.log(formData);
    e.preventDefault();
    if (formData.name != "") {
      setFormStatus("loading");
      const response = await fetch("https://app.4pointtkd-members.com/api/category/" + formData.id, {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrer: "client",
        body: JSON.stringify(formData),
      });

      const json = await response.json();

      if (json.status == "Success") {
        //history.push("/thank-you-for-signing-up");
        // alert("Category updated successfully");
        toast.success('Category updated successfully!', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // window.location.reload();
        setFormStatus("standby");
        setReloadCount(reloadCount + 1);
        setUpdateClassesModalVisible(false);
      } else {
        alert("Category update failed!");
        setFormStatus("standby");

      }
    } else {
      // alert("Please fill up category name!");
      setFormStatus("standby");
      toast.error('Please fill up category name!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function formAddSubmit(e) {
    e.preventDefault();

    if (formAddData.application_id != "" && startDate != "" && startDate != null) {
      console.log("Add Student Submit", formAddData);
      setFormStatus("loading");
      const response = await fetch("https://app.4pointtkd-members.com/api/schedule/" + classScheduleID + "/student", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrer: "client",
        body: JSON.stringify({schedule_id: classScheduleID,date: startDate.toLocaleDateString(), application_id: formAddData.application_id}),
      });

      const json = await response.json();

      if (json.status == "Success") {
        setFormStatus("standby");
        toast.success('Student added successfully!', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // window.location.reload();

        setReloadCount(reloadCount + 1);
        setAddClassesModalVisible(false);
      } else {
        // alert("Adding category failed!");
        setFormStatus("standby");
        toast.error('Adding student failed!', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }
    } else {
      // alert("Please fill up category name!");
      toast.error('Please fill up all fields!', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus("standby");
    }
  }

  return (
   
    <>
      {updateClassesModalVisible ?
        <tr>
          <td>
            <div className="modal fade applicant-modal show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Student Details</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideUpdateCategoriesModal} >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <label>Student's Name</label>
                        <>
                          <div className="select">
                            <select name="application_id" id="day-of-week-select" onChange={handleChangeAdd} className="form-control">

                              {classes.map((category, index) => {
                                return (
                                  <option key={index} value={category.id}>{category.first_name} {category.last_name} {category.id}</option>
                                );
                              })}
                            </select>
                          </div>
                        </>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label>Date</label>
                        <input type="date" className="form-control" name="day_of_week" onChange={handleChange} value={category.birth_date} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideUpdateCategoriesModal} >Close</button>
                    {formStatus != "standby" ?
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={preventDefault}>Saving...</button>
                      :
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={formSubmit}>Save</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        :
        null
      }

      {addClassesModalVisible ?
        <tr>
          <td>
            <div className="modal fade applicant-modal show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="exampleModalLabel">Add New Student</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideAddCategoriesModal} >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <label>Student's Name</label>
                        <>
                          <div className="select">
                            <select name="application_id" id="day-of-week-select" onChange={handleChangeAdd} className="form-control">
                              {classesReady ? 
                                <>
                                  {classesCount > 0 ?
                                    classes.map((student, index) => {
                                      return (
                                        <option key={index} value={student.id}>{student.first_name} {student.last_name}</option>
                                      );
                                    })
                                    :
                                    <option value="" hidden>No Students Found</option>
                                  }
                                </>
                                :
                                <option value="" hidden>Students List Loading...</option>
                              }
                              
                            </select>
                          </div>
                        </>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label>Date</label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          filterDate={isDayAvailable}
                          placeholderText="Select a date"
                          className="form-control"
                        />
                        {/* <input type="date" className="form-control" name="date" onChange={handleChangeAdd} value={formData.date} /> */}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hideAddCategoriesModal} >Close</button>
                    {formStatus != "standby" ?
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={preventDefault}>Adding...</button>
                      :
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={formAddSubmit}>Add</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        :
        null
      }

      {loggedIn === "true" ?
        <Container>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-xs-12">
              <div className="right-sideabr card">
                <center>
                  <img className="user-image" src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" alt="Profile Image" />
                  <h4>
                    {userName}
                    <br />
                  </h4>
                </center>
                <ul className="list-item">
                  <li>
                    <a href="/applications">
                      Applications
                    </a>
                  </li>
                  <li>
                    <a href="/classes">
                      Categories
                    </a>
                  </li>
                  <li>
                    <a href="/classes">
                      Classes
                    </a>
                  </li>
                  <li>
                    <a href="/category-changes">
                      Category Changes
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={logOut}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              {attendeesReady ? 
              <>
              <div className="row mb-4">
                <div className="col-lg-6">
                  <h2 className="mb-2">{formData.name}</h2>
                  <h4 className="mb-3">{new Date('1970-01-01T' + formData.start_time + 'Z').toLocaleTimeString('en-US',{ timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })} - {new Date('1970-01-01T' + formData.end_time + 'Z').toLocaleTimeString('en-US',{ timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' })}</h4>
                </div>
                <div className="col-lg-6 text-right">
                  <button type="button" className="record-action new-category-btn" data-dismiss="modal" onClick={function () { showAddCategoriesModal(); }}><FaPlus className="plus-icon" /> New Student</button>
                </div>
              </div>
              <table className="table table-bordered table-hover bg-white">
                <thead className="thead-light">
                  <tr>
                    <th>Student's Name</th>
                    <th className="applications-head">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {attendeesReady ?
                    <>
                      {attendeesCount > 0 ?
                        attendees.map((attendee, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{attendee.first_name} {attendee.last_name}</td>
                                <td>{attendee.date}</td>
                              </tr>
                            </>
                          );

                        })
                        :
                        <tr>
                          <td scope="row" colSpan="3" className="text-center">No records found</td>
                        </tr>
                      }
                    </>
                    :
                    <tr>
                      <td scope="row" colSpan="3" className="text-center">Loading...</td>
                    </tr>
                  }
                </tbody>
              </table>
              </>
              :
              <div className="text-center">
                <p>Loading...</p>
              </div>
          }
            </div>
          </div>
        </Container>
        :
        null
      }
    </>
  )

}

export default ViewClass