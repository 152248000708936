import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import SignaturePad from "react-signature-canvas";
import validator from 'validator'
import './LoginStyles.css'

const LoginForm = () => {
    let history = useHistory();
    const [submitStatus, setSubmitStatus] = useState("standby");
    const [formStatus, setFormStatus] = useState("standby");
    const [loggedIn, setLoggedIn] = useState(undefined);
    const [formError, setFormError] = useState("");
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      //console.log(formData);
    };

    const testSubmit = (e) => {
        console.log(formData);
    }

    async function formSubmit(e) {
        e.preventDefault();
        setSubmitStatus("loading");
        const response = await fetch("https://app.4pointtkd-members.com/api/login", {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "omit",
          headers: {
            "Access-Control-Allow-Origin" : "*",
            "Access-Control-Allow-Credentials" : true ,
            'Content-Type': 'application/json',
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
          },
          redirect: "follow",
          referrer: "client",
          body: JSON.stringify(formData),
        });

        const json = await response.json();
        if (json.status == "Success") {
          console.log(json);
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem("userData", JSON.stringify(json.data));
          setSubmitStatus("standby");
          setLoggedIn(true);
          history.push("/applications");

          //alert("Sucess"); /* route here */

        } else {
            setFormStatus("error");
            setFormError(json.errors[0]);
            //alert("Please Check your Email or password");
            setSubmitStatus("standby");
        }
    }


    return (
        <Container id="login-form" >
            <div className="row justify-content-center">

                <div className="col-lg-6 login-container">
                    <div className="row">
                        <div className="col-lg-12 text-center mb-4">
                            <h1 id="login-header">Login to your account</h1>
                        </div>
                    </div>
                    <form>
                        {formStatus == "error" ?
                            <div className="col-md-12">
                                <p className="incorrect-credentials bg-danger text-white"><strong>User does not exist!</strong></p>
                            </div>
                            :
                            null
                        }

                        <div className="col-md-12">
                            <p>
                                Email
                            </p>
                            <input type="email" name="email" onChange={handleChange} className="form-control login-input-box" placeholder="Enter email" />
                        </div>
                        <div className="col-md-12">
                            <p>
                                Password
                            </p>
                            <input type="password" name="password" onChange={handleChange} className="form-control login-input-box" placeholder="Enter password" />
                        </div>
                        <div className="col-md-12">
                            {submitStatus == "standby" ?
                                <button type="submit"  onClick={formSubmit} id="login-btn">Log in</button>
                                :
                                <button type="submit" id="login-btn">Logging in</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </Container>
    )
}

export default LoginForm
