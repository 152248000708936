import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Application from './containers/Application'

const Applications = () => {
  let history = useHistory();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userName = userData.user.name;
  const [modalClass, setModalClass] = useState("modal fade");
  const loggedIn = sessionStorage.getItem("loggedIn");
  const [applicantsCount, setApplicantsCount] = useState(0);
  const [applicants, setApplicants] = useState([]);
  const [filterStatus, setFilterStatus] = useState("standby");
  const [sortStatus, setSortStatus] = useState("standby");
  const [reloadCount, setReloadCount] = useState(1);
  const [categories, setCategories] = useState([]);
  const [applicantsReady, setApplicantsReady] = useState(false);

  var curr = new Date();
  curr.setDate(curr.getDate());
  var today = curr.toISOString().substr(0, 10);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    gender: "",
    city: "",
    media_release: "",
    sort_by: "",
    order: "ASC",
    date_submitted: today,
    notes: "",
    birth_date: "",
    category_id: ""
  });

  const renderApplicants = applicants.map((applicant) => {
    return <Application applicant={applicant} key={applicant.id} />;
  });

  const showModal = (e) => {
    setModalClass("modal fade show");
    const applicantId = e.target.value;
  }

  const hideModal = (e) => {
    setModalClass("modal fade");
  }

  const logOut = (e) => {
    sessionStorage.clear();
    history.push("/login");
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const retrieveApplication = async (e) => {
    const response = fetch('https://app.4pointtkd-members.com/api/application/' + e, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveCategories = async () => {
    const response = fetch('https://app.4pointtkd-members.com/api/category', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  // const getAplicationData = (e) => {
  //   const getApplication = async (e) => {
  //       const selectedApplication = await retrieveApplication(e);
  //       // console.log(selectedCategory)
  //       // setFormData({
  //       //     ...formData,
  //       //     id: selectedApplication.id,
  //       //     name: selectedApplication.name,
  //       //     applications: selectedCategory.applications,
  //       //   });
  //       // console.log("length of gallery:", selectedGallery.length);
  //       console.log(selectedApplication);
  //   }
  //   getApplication(e);
  // }

  const handleChangeSort = (e) => {
    var sort_value = e.target.value;

    setFormData({
      ...formData,
      [e.target.name]: e,
    });


  }

  const applyFilter = (e) => {
    setFilterStatus("loading");
    const retrieveApplicants = async () => {
      //const response = fetch(`https://adrienb1.sg-host.com/api/account`, {
      const response = fetch('https://app.4pointtkd-members.com/api/application?first_name=' + formData.first_name + '&last_name=' + formData.last_name + '&email_address=' + formData.email_address + '&media_release=' + formData.media_release + '&gender=' + formData.gender + '&city=' + formData.city + '&sort_by=' + formData.sort_by + '&order=' + formData.order + '&date_submitted=' + formData.date_submitted + '&category_id=' + formData.category_id + '&birth_date=' + formData.birth_date, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          //'Access-Control-Allow-Origin': "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Content-Type': 'application/json',
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
      })
        .then((response) => {
          return response.json();
        })
        .then((events) => {
          return events.data;
        });
      return response;
    };

    const getApplicants = async () => {
      const selectedApplicants = await retrieveApplicants();
      //console.log(selectedApplicants);
      if (selectedApplicants) {
        setApplicants(selectedApplicants);
        setApplicantsCount(selectedApplicants.length);
        setFilterStatus("standby");
        setReloadCount(reloadCount + 1);
      }
    };
    getApplicants();
  }

  /*const sort = (e) => {
      setSortStatus("loading");
      const retrieveApplicants = async () => {
          //const response = fetch(`https://adrienb1.sg-host.com/api/account`, {
          const response = fetch('https://app.4pointtkd-members.com/api/application?sort_by='+formData.sort_by+'&order='+formData.order+'&date_submitted='+formData.date_submitted, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            headers: {
              //'Access-Control-Allow-Origin': "*",
              "Access-Control-Allow-Origin" : "*",
              "Access-Control-Allow-Credentials" : true ,
              'Content-Type': 'application/json',
              "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
              "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'client', // no-referrer, *client
          })
            .then((response) => {
              return response.json();
            })
            .then((events) => {
              return events.data;
            });
          return response;
      };
      const getApplicants = async () => {
        const selectedApplicants = await retrieveApplicants();
        //console.log(selectedApplicants);
        setApplicants(selectedApplicants);
        setApplicantsCount(selectedApplicants.length);
        setSortStatus("standby");
      };
      getApplicants();
  }*/

  const retrieveApplicants = async () => {
    //const response = fetch(`https://adrienb1.sg-host.com/api/account`, {
    const response = fetch('https://app.4pointtkd-members.com/api/application', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  useEffect(() => {
    const getApplicants = async () => {
      const selectedApplicants = await retrieveApplicants();
      //console.log(selectedApplicants);
      if (selectedApplicants) {
        setApplicantsReady(true);
        setApplicants(selectedApplicants);
        setApplicantsCount(selectedApplicants.length);
      } else {
        setApplicantsReady(true);
      }
    };
    getApplicants();

    const getCategories = async () => {
      const selectedCategories = await retrieveCategories();
      if (selectedCategories) {
        // console.log("Selected Categories", selectedCategories);
        setCategories(selectedCategories);
        // setCategoriesCount(selectedCategories.length);
        // setFormData({
        //   ...formData,
        //   id: selectedCategories.id,
        //   name: selectedCategories.name,
        //   created_at: selectedCategories.created_at,
        // });
        // console.log("Form Data", formData);
      }
    };
    getCategories();

  }, []);

  return (
    <>
      {loggedIn === "true" ?
        <Container>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-xs-12">
              <div className="right-sideabr card">
                <center>
                  <img className="user-image" src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png" alt="Jomar Bruto" />
                  <h4>
                    {userName}
                    <br />
                  </h4>
                </center>
                <ul className="list-item">
                  <li>
                    <a href="/applications">
                      <i className="fa fa-envelope"></i>
                      Applications
                    </a>
                  </li>
                  <li>
                    <a href="/categories">
                      Categories
                    </a>
                  </li>
                  <li>
                    <a href="/classes">
                      Classes
                    </a>
                  </li>
                  <li>
                    <a href="/category-changes">
                      Category Changes
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={logOut}>
                      <i className="fa fa-envelope"></i>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="card">
                {/*<div className="card-header">
                            <h5 className="mb-0">Search</h5>
                        </div>*/}
                <div className="card-body">
                  <div className="row" id="search_row">
                    <div className="col-lg-12">
                      <h5 className="mb-3">Search</h5>
                    </div>
                    <div className="col-lg-4">
                      <label>First Name</label>
                      <input type="text" onChange={handleChange} className="form-control" placeholder="First Name" name="first_name" id="first_name" value={formData.first_name} required />
                    </div>
                    <div className="col-lg-4">
                      <label>Last Name</label>
                      <input type="text" onChange={handleChange} className="form-control" placeholder="Last Name" name="last_name" id="last_name" value={formData.last_name} required />
                    </div>
                    <div className="col-lg-4">
                      <label>Email Address</label>
                      <input type="text" onChange={handleChange} className="form-control" placeholder="Email Address" name="email_address" id="email_address" value={formData.email_address} required />
                    </div>
                    <div className="col-lg-4">
                      <label>Date of Birth</label>
                      <input type="date" value={formData.birth_date} onChange={handleChange} name="birth_date" className="form-control" />
                    </div>
                    <div className="col-lg-4">
                      <label>Signed Media Release</label>
                      <div className="select">
                        <select onChange={handleChange} value={formData.media_release} className="select-input" name="media_release">
                          <option value=""></option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label>Gender</label>
                      <div className="select">
                        <select onChange={handleChange} value={formData.gender} className="select-input" name="gender">
                          <option value=""></option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label>City</label>
                      <input type="text" onChange={handleChange} className="form-control" placeholder="City" name="city" id="city" value={formData.city} required />
                    </div>
                    <div className="col-lg-4">
                      <label>Date Submitted</label>
                      <input type="date" /*value={formData.date_submitted}*/ onChange={handleChange} name="date_submitted" className="form-control" />
                    </div>
                    {categories ?
                      <>
                        {categories.length > 0 ?
                          <div className="col-lg-4">
                            <label>Category</label>
                            <div className="select">

                              <select onChange={handleChange} value={formData.category_id} className="select-input" name="category_id">
                                <option value=""></option>
                                {categories.map((category, index) => {
                                  return (
                                    <option value={category.id}>{category.name} </option>
                                  );
                                })
                                }
                              </select>
                            </div>
                          </div>
                          :
                          <option value=""></option>
                        }
                      </>
                      :
                      <option value=""></option>
                    }

                    <div className="col-lg-12 mb-3">
                      <div className="row" id="sort_row">
                        <div className="col-lg-12">
                          <h5 className="mb-3">Sort</h5>
                        </div>
                        <div className="col-lg-6">
                          <label>Sort By:</label>
                          <div className="select">
                            <select onChange={handleChange} value={formData.sort_by} className="select-input" name="sort_by">
                              <option value=""></option>
                              <option value="first_name">First Name</option>
                              <option value="last_name">Last Name</option>
                              <option value="email_address">Email Address</option>
                              <option value="city">City</option>
                              <option value="birth_date">Date of Birth </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <label>Order</label>
                          <div className="select">
                            <select onChange={handleChange} value={formData.order} className="select-input" name="order">
                              <option value="ASC">Ascending</option>
                              <option value="DESC">Descending</option>

                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          {filterStatus == "loading" ?
                            <button className="record-action mt-2">
                              Applying Filter...
                            </button>
                            :
                            <button className="record-action mt-2" onClick={applyFilter}>
                              Apply Filter
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-hover bg-white" id="applicants-table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Contact</th>
                    <th scope="col" className="gender">Gender</th>
                    <th scope="col">City</th>
                    <th scope="col">Notes</th>
                    <th scope="col" className="signed">Signed</th>
                    <th scope="col" className="view-header"></th>
                  </tr>
                </thead>
                <tbody>
                  {applicantsReady ?
                    <>
                      {applicantsCount > 0 ?
                        <>
                          {renderApplicants}
                        </>
                        :
                        <tr>
                          <td scope="row" colSpan="7" className="text-center">No records found</td>
                        </tr>
                      }
                    </>
                    :
                    <tr>
                      <td scope="row" colSpan="7" className="text-center">Loading...</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Container>
        :
        null
      }
    </>

  )
}

export default Applications