import React from 'react'
import { useHistory } from 'react-router-dom'
import Meta from '../components/Meta'
import SingleClass from '../components/ViewClass'
import '../css/ApplicationStyles.css'

const ClassesPage = () => {
    let history = useHistory();
    const loggedIn = sessionStorage.getItem('loggedIn');
    console.log(loggedIn);
    // page content
    const pageTitle = 'View Class'

  return (
    <div id="schedules-page">
      <Meta title={pageTitle}/>
      {loggedIn !== "true" ?
          history.push("/login")
          :
          <SingleClass />
      }
    </div>
  )
}

export default ClassesPage
